import React, {useEffect, useState} from 'react';
import {Link, Outlet, useLocation} from "react-router-dom";
import './error.scss';
import Header from "./Header";
import Footer from "./Footer";

function Error({errorCode}) {
  // const location = useLocation();
  // const [errorCode, setErrorCode ] = useState(0);
  // useEffect(() => {
  //   if(errorCode === null)
  //     setErrorCode(location.pathname.split("error/")[2])
  // }, [ location, errorCode])
  // ******************************************
  return (
    <>
      <Header type={"error"}/>
      <article className="error_box">
        {errorCode === 401 &&
          <div className="error_content 401_error_content">
            <img className="error_img"
                 src={process.env.PUBLIC_URL + "/images/error401.png"}
                 alt="accordion icon"/>
            <div className="error_text">
              해당 페이지에 접근 할 수 있는 권한이 없습니다.
            </div>
            <button type="button">
              홈으로 이동
            </button>
          </div>
        }

        {(errorCode === 404 || errorCode === "") &&
          <div className="error_content 404_error_content">
            <img className="error_img"
                 src={process.env.PUBLIC_URL + "/images/error404.png"}
                 alt="accordion icon"/>
            <div className="error_text">
              페이지의 주소가 잘못 입력되었거나,<br/>
              주소 변경 혹은 삭제되어 페이지를 찾을 수 없습니다.
            </div>
            <Link to="/" className="move_home_btn">
              홈으로 이동
            </Link>
          </div>
        }
      </article>
      <Footer/>
    </>
  )
}

export default Error;

