import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getNoticeById} from "../../api/api";
import Loading from "pages/layout/Loading";


function NoticeDetail() {
  const params = useParams();
  let id = params.id;
  const [notice, setNotice] = useState(); // 이용 약관

  const {isLoading, error, data,} = useQuery(
    ['notice',id],
    () => getNoticeById(id).then(res=>res.data)
    ,{

      // 리스트일때 이 값을 주어버리면 id가 변경 되어도 새로 리렌더링을 안함
      // fresh 상태일때는 쿼리 인스턴스가 새롭게 mount 되어도 네트워크 fetch가 일어나지 않는다.
      // staleTime:Infinity,
      keepPreviousData: true,
      onSuccess:data=>{
        setNotice(data.content);
        window.aaa = notice;
      }
    }
  );
  if(isLoading || !notice) return <Loading></Loading>;
  if(error) return <div>{error}</div>
 // ******************************************
  return (
    <div id="notice">

      <div className="sub_header bg4">
        <div className="box">
          <div className="main_title sub">
            Notice
          </div>
        </div>
      </div>

      <div className="full_box">
          <div className="notice_detail_box">

            {/* 공지사항 내용 */}
            <div className="text_box">
              <div className="title">
                {notice.noticeTitle}
              </div>
              <div className="date">
                {notice.date}
              </div>
              <div className="text" dangerouslySetInnerHTML={ {__html:notice.noticeContent}}></div>

              {/* 이전, 다음 버튼*/}
              <div className="pc_btn_box btn_box">
                {notice.preIdx && <Link className="bottom_btn prev" to={`/notice/${notice.preIdx}`}>
                  이전
                </Link>}
                {notice.nextIdx && <Link className="bottom_btn next" to={`/notice/${notice.nextIdx}`}>다음</Link>}
                <Link className="bottom_btn list" to="/notice">목록</Link>
              </div>
              <div className="mobile_btn_box btn_box">
                {notice.preIdx && <Link className="bottom_btn prev" to={`/notice/${notice.preIdx}`}></Link>}
                {notice.nextIdx && <Link className="bottom_btn next" to={`/notice/${notice.nextIdx}`}></Link>}
                <Link className="bottom_btn list" to="/notice"></Link>
              </div>
            </div>
          </div>
      </div>
    </div>

  )
}
export default NoticeDetail;