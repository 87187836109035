// 5개년 트렌드 가져오는 sql
/*
SELECT * FROM data_trainee_trend
WHERE trainee_t_a_column = "trend1"
AND trainee_t_year > 2017
ORDER BY trainee_t_year ASC
*/

// 2021 : 5개년 트렌드 - 주당 평균 근무시간 - 전체 트렌드
// const trendAllData = [91.8062, 87.9018, 79.3226, 80.0195, 76.697]; // 차례대로 2016, 2017, 2018, 2019 의 trendAllDataLabel

// 2022 : 5개년 트렌드 - 5개년 트렌트 - 전체 트렌드(2018, 2019, 2020, 2021, 2022)
const trendAllData = [79.3226, 80.0195, 76.697, 77.2409, 73.608];

// 2021 : 5개년 트렌드 - 주당 평균 근무시간 - 규모 별 비교 (Bundled Chart)=============================================================================
const trendScaleData1 = [80.9042,80.2617,79.556,78.152,79.285]; // data_trainee_trend.type_big(중대형병원 데이터)
const trendScaleData2 = [81.5802,81.5294,81.822,80.7379,79.774]; // type_midbig(중대형병원 데이터)
const trendScaleData3 = [80.1666,81.0113,81.289,79.2556,77.347]; // type_midsmall(중소형병원 데이터)
const trendScaleData4 = [81.4092,73.21,71.358,74.3823,67.715]; // type_small(소형병원 데이터)

// 2021 : 5개년 트렌드 - 주당 평균 근무시간 - - Big 5 병원 (Bundled Chart)=============================================================================
const trendBig5Label1 = "가톨릭중앙의료원";
const trendBig5Label2 = "삼성서울병원";
const trendBig5Label3 = "서울대병원";
const trendBig5Label4 = "서울아산병원";
const trendBig5Label5 = "연세대세브란스병원";

const trendBig5Data1 = [87.3009,84.2667,82.755,80.3611,80.56]; // 차례대로 data_trainee_trend.big5_1
const trendBig5Data2 = [80.296,78.8,78.623,76.1161,77.26]; // big5_2
const trendBig5Data3 = [73.8722,75.209,73.683,72.3281,75.43]; // big5_3
const trendBig5Data4 = [82.0803,80.1186,80.989,78.3072,81.44]; // big5_4
const trendBig5Data5 = [87.307,86.4721,83.017,81.0479,83.27]; // big5_5


// 2021 : 5개년 트렌드 - 주당 평균 근무시간 - - 수련연차 별 비교 (Bundled Chart)=============================================================================
const trendAnnualData1 = [88.6252,87.9544,87.9488,86.9848,88.215]; // '인턴' 데이터(traineeyear_0)
const trendAnnualData2 = [87.0612,86.0373,85.4706,81.9549,81.871,6]; // '레지1년차' 데이터(traineeyear_1)
const trendAnnualData3 = [82.2217,81.3785,82.137,79.6338,79.457]; // '레지2년차' 데이터(traineeyear_2)
const trendAnnualData4 = [73.8234,74.6104,74.5784,74.5861,71.494]; // '레지3년차' 데이터(traineeyear_3)
const trendAnnualData5 = [69.5882,68.3305,66.7059,68.4819,63.286]; // '레지4년차' 데이터(traineeyear_4)

// 2021 : 병원별 평가 상세결과
const hospitalAllOverlayData = [73.4, 65.4, 58.5, 63, 66.2];  // data_trainee_appraisal // 차례대로 삼성서울병원의 급여(money_per), 복리후생(welfare_per), 근로여건(work_per), 전공의 안전(safety_per), 교육환경(training_per)
const hospitalAllOverlayDataAvg = [73.633, 70.704, 58.424, 63.345, 60.908];  // 차례대로 평균 데이터

//*********************************************************************************************************
// chart.js 공통 옵션
export const commonOption={
  responsive: true,
  maintainAspectRatio: false, // false로 설정 시 사용자 정의 크기에 따라 그래프 크기가 결정됨.
  maxBarThickness: 22,// bar 타입의 경우 막대의 최대 굵기
  plugins: {
    legend:{
      position:"bottom", // 범례의 위치를 아래로 변경(기본값 top)
    },
  },
}

// START ********************************* 트렌드 : 전체 트렌드 *********************************

const trendAllDataLabel = "주당 평균 근무시간";

export const trendAllOption={
   ...commonOption,
  scales: {
    y: {
      max: 95,
      min: 70,
      type: 'linear',
      ticks: {
        fontSize:12,
        fontColor: 'red',
      },
    }
  }
}
export const trendAllChartData = {
  labels: ["2017", "2018", "2019", "2020", "2021"],
  datasets: [{
    label: trendAllDataLabel,
    data: trendAllData,
    backgroundColor: '#3a50f1', // 원 안의 색상
    borderColor: 'rgba(58,80,241,1)', // 원의 테두리 색상
  }]
}
// END ********************************* 트렌드 : 전체 트렌드 *********************************

// START ********************************* 트렌드 : 규모별 비교 *********************************
// export const trendScaleOption={
//   ...commonOption,
// }
export const trendScaleChartData = {
  labels: ["2017", "2018", "2019", "2020", "2021"],
  datasets: [{
    label: "대형병원",
    backgroundColor: "#2939da",
    data: trendScaleData1,
  }, {
    label: "중대형병원",
    backgroundColor: "#4667f6",
    data: trendScaleData2,
  }, {
    label: "중소형병원",
    backgroundColor: "#7199ff",
    data: trendScaleData3,
  },{
    label: "소형병원",
    backgroundColor: "#a7c9ff",
    data: trendScaleData4,
  }]
}
// END ********************************* 트렌드 : 규모별 비교 *********************************

// START ********************************* Big 5 병원 *********************************
export const trendBig5Option={
  ...commonOption,
  maxBarThickness: 19,
}
export const trendBig5ChartData = {
  labels: ["2017", "2018", "2019", "2020", "2021"],
  datasets: [{
    label: trendBig5Label1,
    backgroundColor: "#2939da",
    data: trendBig5Data1,
  }, {
    label: trendBig5Label2,
    backgroundColor: "#4667f6",
    data: trendBig5Data2,
  }, {
    label: trendBig5Label3,
    backgroundColor: "#7199ff",
    data: trendBig5Data3,
  },{
    label: trendBig5Label4,
    backgroundColor: "#a7c9ff",
    data: trendBig5Data4,
  },{
    label: trendBig5Label5,
    backgroundColor: "#c3dfff",
    data: trendBig5Data5,
  }]
}
// END ********************************* Big 5 병원 *********************************

// START ********************************* Big 5 병원 *********************************
export const trendAnnualChartData = {
  labels: ["2017", "2018", "2019", "2020", "2021"],
  datasets: [{
    label: "인턴",
    backgroundColor: "#2939da",
    data: trendAnnualData1,
    borderWidth: 0
  }, {
    label: "레지1년차",
    backgroundColor: "#4667f6",
    data: trendAnnualData2,
    borderWidth: 0
  }, {
    label: "레지2년차",
    backgroundColor: "#7199ff",
    data: trendAnnualData3,
    borderWidth: 0
  },{
    label: "레지3년차",
    backgroundColor: "#a7c9ff",
    data: trendAnnualData4,
    borderWidth: 0
  },{
    label: "레지4년차",
    backgroundColor: "#c3dfff",
    data: trendAnnualData5,
    borderWidth: 0
  }]
}
// END ********************************* Big 5 병원 *********************************


// START ********************************* 병원 별 평가 상세결과 *********************************
const hospitalAllOverlayLabel = [
  "급여",
  "복리후생",
  "근로여건",
  "전공의 안전",
  "교육환경"
];


const hospitalName = "삼성서울병원";

// Rador chart 옵션
export const radarChartOptions = {
  interaction: {
    intersect: false, // x축을 각각 분리해서 툴팁을 표현할것인가?
    mode: 'index', // (툴팁을 표현하는 방법 => 해당 같은 x 축의 내용 모두 노출)
  },
  // scale은 단일 축을 정의할 때 사용하고, scales는 다중 축을 정의할 때 사용합니다. 헷갈리지 말것..
  // 그래서 radar 차트의 경우에는 scale 대신 scales를 사용해야 합니다. scales를 사용하면 각 축에 대한 구성을 배열로 나열할 수 있습니다.
  scales: {
    r: {
      pointLabels: {
        font: {
          weight: '600',
          size:12
        }
      },
      ticks: {
        display: false, // 그리드 간격 숫자를 숨김
        stepSize: 10 // 그리드 간격 값
      },
      beginAtZero: true,
      min: 0, // 축 최소 값
      max: 100, // 축 최대 값
    },
  },
  legend: {
    position: "top", // 범례의 위치를 아래로 변경(기본값 top)
  },
};

// fill 하기 위해서는 import {Chart, Filler} from 'chart.js';
// Chart.register(Filler);  옵션이 반드시 필요함
export const hospitalAllChartData={
  labels: hospitalAllOverlayLabel,
  datasets: [
  {
    label: hospitalName,
    data: hospitalAllOverlayData,
    fill:true,
    backgroundColor: 'rgba(58,80,241,0.4)',
    borderColor: '#3A50F1',
  },
  {
    label: '전체 병원 평균',
    data: hospitalAllOverlayDataAvg, // 평균값
    fill:true,
    backgroundColor: 'rgba(207,15,38,0.4)',
    borderColor: '#cf0f26',
  }]
}
// END ********************************* 병원 별 평가 상세결과 *********************************