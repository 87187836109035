export const rankingSubject = {
    all : "수련병원",
    money: "급여",
    training: "교육환경",
    work: "근로여건",
    welfare: "복리후생",
    safety: "전공의 안전",
  }
;
export const rankingQuestion =
  {
    all : [],
    money: [
      "선생님의 한달 급여는 평균 얼마입니까?",
      "선생님은 수련 계약서 2부 작성 후 본인 보관용 1부를 돌려 받았습니까?"
    ],
    training: [
      "선생님은 소속된 병원의 수련환경에 만족하십니까?",
      "전공의(인턴, 레지던트) 선발과정이 공정하고 객관적으로 이루어진다고 생각하십니까?",
      "선생님께서 지식 또는 술기를 배우고자 할 때 기회가 충분히 제공됩니까?",
      "모든 수련병원 및 기관에는 전공의 지도 및 교육을 담당하도록 지정된 ‘지도 전문의’가 있습니다. 선생님은 이에 대해서 얼마나 알고 계셨나요?",
      "선생님께서 수행하는 업무 중 수련과 관련 없는 업무가 차지하는 비중은 얼마입니까?",
      "선생님께서는 당직 근무 중 환자에게 문제가 발생했을 때 전문의에게 즉시 보고 및 적절한 자문을 구할 수 있습니까?",
      "모든 전공의는 각 학회에서 지정한 연차별 교육수련 목표에 따라 수련 받을 것이 권장됩니다. 선생님의 병원은 현재 교육수련 목표에 따른 수련과정을 제공하고 있습니까?",
      "전공의 수련 프로그램을 마치고 나면 전문의로서의 충분한 역량을 키울 수 있을 것이라고 생각하십니까?",
      "선생님께서는 환자에게 술기를 행할 때, 전문의에게 적절한 지도 및 감독을 받을 수 있습니까?",
      "선생님의 병원은 교육에 필요한 시설 및 기자재를 충분히 제공하고 있습니까?",
      "선생님의 가족이 아플 때, (환자 안전 및 수준 높은 치료 측면에서) 선생님께서 수련 중인 병원으로 모시고 올 의향이 있습니까?",

    ],
    work: [
      "선생님의 1주일 평균 업무시간은 얼마입니까?",
      "선생님께서 1주일에 최대로 일한 업무시간은 얼마입니까?",
      "선생님의 1일 업무 중 초과근무에 해당하는 시간은 얼마입니까?",
      "최대로 연속해서 근무한 시간은 몇 시간이었습니까?",
      "전날 업무를 마친 후 다음 정규시작 전까지 휴식시간은 평균 몇 시간 이었습니까?",
      "30분 이상의 점심 식사 시간을 가졌던 경우가 일주일에 평균 몇 번이었습니까?",
      "1년간 실제로 사용할 수 있는 휴가는 총 몇 일입니까? (주말/휴일 제외)",
      "보건복지부에서는 입원환자의 안전 강화 및 진료효율성 증대, 전공의 수련환경 개선에 따른 의료인력 공백 해소를 위해 2016년 9월부터 입원전담전문의 제도를 시범사업으로 시행하고 있습니다. 선생님께서 근무하는 병원에는 입원전담전문의를 고용하고 있습니까?",
      "선생님께서는 정규 근무 시 입원 환자를 평균 몇 명 담당합니까?",
      "선생님께서는 당직 근무 시 최대 몇 명의 환자를 담당합니까?",
    ],
    welfare: [
      "전반적인 근무 환경에 대한 만족도를 점수로 표시해주세요.",
      "전공의를 위한 충분한 휴게공간 (당직실 포함)이 있습니까?",
      "전공의 복지와 관련해 담당부서 (교육수련부 등) 의 지원 및 역할에 만족하십니까?",
      "선생님은 업무 중 번아웃 증후군의 증상을 경험한 적이 얼마나 있으십니까? (번아웃 증후군의 증상 : 피로감, 무가치함, 업무 효율성 저하)",
    ],
    safety: [
      "선생님께서는 병원 내부 구성원 (의료진)으로부터 폭력을 당한 적이 있습니까? (폭력 : 폭언, 폭행, 성희롱, 성추행 등 모든 종류의 폭력을 포함합니다)",
      "선생님께서는 병원에서 근무하면서 환자 및 보호자로부터 폭력을 당한 적이 있습니까? (폭력 : 폭언, 폭행, 성희롱, 성추행 등 모든 종류의 폭력을 포함합니다)",
      "병원내 폭력 사건 발생시 병원내 처리절차를 신뢰하십니까?",
      "병원이 감염원에 노출되는 상황(결핵, HIV, 간염바이러스, 매독, 홍역 등)을 방지하기 위해 적절한 조치(보호구 제공, 노출 시 사후 조치 등)를 취하고 있습니까?",
      "병원이 방사능 노출로 인한 위험을 방지하기 위해 적절한 조치(보호구 제공, 계수기 부착, 노출량 관리 등)를 취하고 있습니까?",
    ],
  }
;