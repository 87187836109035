import React, {useContext, useMemo} from 'react';
import "./survey.scss"
import useMoveScroll from "../../test/useMoveScroll";
import {ReactComponent as SurveyIcon} from "images/icons/sub_tab_icon_survey1.svg";
import {ReactComponent as SurveyIconTwo} from "images/icons/sub_tab_icon_survey2.svg";
import Fade from "react-reveal/Fade";
import {
  ModalDispatchContext,
  ModalStateContext
} from "../../components/modal/ModalContext";
import Modal from "../../components/modal/Modal";
import ContactUs from "../../components/modals/ContactUsPopup";
import ImageSlider from "../../components/ImageSlider";
import Slider from "react-slick";
import classnames from "classnames";
const Survey = () => {
  const scrollItems = [
    useMoveScroll(),
    useMoveScroll(),
  ];
  const {state} = useContext(ModalStateContext)
  const {open} = useContext(ModalDispatchContext)
  const openModal = (e) => {open(e.target.id);}
  const commonUrl = "/images/capture/survey/";
  const mobileMockUpList = useMemo(() => [
    {
      url:commonUrl+"survey_list.png",
      alt:"설문조사 리스트",
    },
    {
      url:commonUrl+"survey_entrance.png",
      alt:"설문 초기 화면",
    },
    {
      url:commonUrl+"survey_ing.png",
      alt:"설문 진행중 화면",
    },
  ]);
  // ============================================
  return (
    <article className="C_Survey">
      <div className="header">
        <div
          className="header_bg_img"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/survey/header_background_image.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize:"cover"
          }}
        >
          Survey
        </div>

        <div className="tab_box c_max_width">
          <div
            className="each_tab_box"
            onClick={scrollItems[0].onMoveToElement}>
              <SurveyIcon/>
            <p>리워드 혜택 누리기</p>
          </div>
          <div
            className="each_tab_box"
            onClick={scrollItems[1].onMoveToElement}>
            <SurveyIconTwo/>
            <p>설문조사 상담받기</p>
          </div>
        </div>
      </div>

      <div className="content">

        <div className="content_box">

          <div className="content_box_header bg_white" ref={scrollItems[0].element}>
            <h1>리워드 혜택 누리기</h1>
            <div className="h1_bottom_box"></div>
          </div>

          <div className="content_box_body bg_white">
            <div className="number">1</div>
            <h2 className="black">설문조사</h2>
            <p>의사선생님들께서는 맞춤형 설문조사에 참여하고, 메디스태프의 리워드 MEDIT도 받을 수 있습니다.
              </p>
            <p>
              리워드로 지급받은 MEDIT은 현금처럼 사용이 가능합니다.
            </p>
            <div className="image_box">
              <img
                className="c_mb_100"
                src={`${process.env.PUBLIC_URL}/images/survey/img.png`} alt="survey header"/>
            </div>
          </div>

          <div className="content_box_body bg_grey">
            <div className="number c_mt_100">2</div>
            <h2 className="black">의사선생님이라면 설문 참여 후 리워드 획득!</h2>
            <p>리워드 지급 전용 설문조사는 설문에 참여만 해도 MEDIT을 아낌없이 지원합니다.
            </p>
            <p>
              마음에 드는 설문을 찾고, 설문하기를 클릭하면 바로 설문이 시작됩니다.
            </p>
            <p>
              설문을 완료해 주시면 MEDIT을 획득할 수 있습니다.
            </p>
            <div className="image_box list_pc">
              <Fade bottom>
                {mobileMockUpList.map((item, index)=>(
                  <div className="item" key={index} data-aos="fade-up">
                    <div className="frame">
                      <img
                        className="aaa"
                        src={require('../../images/iphone_frame.svg').default} alt="icon"/>
                      <img
                        className="bbb"
                        src={process.env.PUBLIC_URL+item.url} alt={item.alt}/>
                    </div>
                  </div>
                ))}
              </Fade>
            </div>

            <div className="image_box list_mobile">
              {/* 모바일의 경우 */}
              <ImageSlider
                page="survey"
                imageList={mobileMockUpList}
              />
            </div>
          </div>
        </div>

        <div className="content_box bg_img">
          <img
            className="z-10"
            src={`${process.env.PUBLIC_URL}/images/survey/middle_background_image.png`} alt="survey middle"/>
        </div>

        <div className="content_box" ref={scrollItems[1].element}>

          <div className="content_box_header ">
            <h1>설문조사 상담받기</h1>
            <div className="h1_bottom_box"></div>
          </div>

          <div className="content_box_body bg_white">
            <h2 className="point">세밀하고 정확한 고객 타겟팅</h2>
            <p>리워드 지급 전용 설문조사는 설문에 참여만 해도 MEDIT을 아낌없이 지원합니다.
            </p>
            <div className="image_box">
              <img
                className="c_mb_100"
                src={`${process.env.PUBLIC_URL}/images/survey/img_2.png`} alt="survey header"/>
            </div>
          </div>

          <div className="content_box_body bg_grey c_mt_100">
            <h2 className="point">설문조사는 분석이 더 중요하니까 </h2>
            <p>설문조사를 종료하면 메디스태프에서는 데이터를 정교하게 분석하여 보고서를 제공합니다.
            </p>
            <div className="image_box">
              <img
                className="c_mb_100"
                src={`${process.env.PUBLIC_URL}/images/survey/img_3.png`} alt="survey header"/>
            </div>
          </div>
        </div>

        <div className="content_box email"
             style={{
               backgroundImage:`url(${process.env.PUBLIC_URL}/images/survey/bottom_background_image.jpg)`,
               backgroundRepeat: "no-repeat",
               backgroundSize:"cover"
             }}
        >
          <div className="c_max_width">
            <div className="title">설문조사는 메디스태프에서</div>
            <div className="text">
              <p>빠르게 성장하는 의사 선생님들의 보안 플랫폼</p>
              <p>메디스태프에서 발빠르게 설문조사를 시작해 보세요.</p>
            </div>
            <button
              onClick={openModal} id="contactUsModal_SU"
            >설문조사 상담받기 (기업용)</button>
          </div>
        </div>
      </div>
      <Modal
        id="contactUsModal_SU"
        openedModals={state.openedModals}
        header="Contact us">
        <ContactUs
          inquiryType="SU"
        />
      </Modal>
    </article>
  );
};

export default Survey;
