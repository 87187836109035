import React, {useEffect, useState} from 'react';
import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {getPress} from "../api/api";
import "./press.scss"
import {useInView} from "react-intersection-observer";


export default function Press() {
  // const { ref, inView} = useInView();
  const limitCount = 10; // 한번에 몇개씩 불러올 것인지
  /* 데이터 불러오기 */
  const { isLoading, isError, error, data, fetchNextPage, isFetchingNextPage, hasNextPage,fetchPreviousPage,hasPreviousPage } =
    useInfiniteQuery(
      ['press'],
      ({ pageParam = 0 }) => getPress(pageParam, limitCount),
      {
        staleTime: 5000, // 5초
        cacheTime: Infinity, // 제한 없음
        refetchOnWindowFocus: false,

        // 다음 페이지를 호출할 때 사용 될 pageParam
        getNextPageParam: (lastPage, allPages) => {
          const nextPageParam = Number(allPages.length);
          return !lastPage.data.last && nextPageParam;
        },

        // 이전 페이지를 호출할 때 사용 될 pageParam
        getPreviousPageParam: (firstPage, allPages) => {
          return allPages.length - 1;
        },
        onSuccess:(data) => {
          // console.log("data : "+ JSON.stringify(data));
        },
        retry:false
      }
    )

  // 페이지의 가장 끝 부분에 도달했을때 자동으로 불러오기 기능
  // useEffect(()=> {
  //   if(inView){
  //     fetchNextPage();
  //   }
  // }, [inView])

  if (isLoading) return <h2>Loading...</h2>
  if (isError) return <div className="error_box">{error.message}</div>


  // ******************************************
  return (
    <div id="press">
      <div id="contents">
        <div className="sub_header bg3">
          <div className="box">
            <div className="main_title sub">
              Press
            </div>
          </div>
        </div>

        <div className="full_box">
          <div className="press_box">
            {
              data.pages.map((item) => {
                return item.data.content.map((v)=>(
                  <PressList
                    key={v.newsIdx}
                    press={v}/>
                ))
              })
            }
          </div>
          <div className="btn_box" id="pressBtnBox">
            {hasNextPage &&<button className="btn_s" onClick={() => hasNextPage && fetchNextPage()}>View more</button>}
          </div>
        </div>
      </div>
    </div>
  );
};
// ***************************************************************
export function PressList({press}) {
  return (
    <a href={press.newsUrl} target="_blank">
    <div className="press_card">
      <div className="date_box">
        <div className="wrapper_circle">
          <div className="month">{press.month}</div>
        </div>
        <div className="day">{press.year}</div>
      </div>

      <div className="press_in">
        <div className="text_box">
          <div className="title">
            {press.newsTitle}
          </div>
          <div className="sub_title">
            {press.date}
            <span>{press.newsPublisher}</span>
          </div>
          <div className="content">
            {press.newsContent}
          </div>
        </div>
      </div>
    </div>
    </a>
  )
}
