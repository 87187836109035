import React from 'react';
import {appStoreClick} from "../../utils/common";

function TraineeBottom() {
 // ******************************************
  return (
    <div id="trainee_bottom_banner">
      <div className="main_store banner"
         style={{
           backgroundImage: `url(${process.env.PUBLIC_URL}/images/trainee/main_store_img.svg)`,
           backgroundColor: "#E0EAF5",
           backgroundPosition: "60% 39%",
           backgroundRepeat: "no-repeat"
         }}
      >
        <div className="content_box store">
          <div className="in_text">
            우리가 꼭 알아야 하는 정보<br/>
            의사만을 위한 서비스 메디스태프에서 제공합니다.
          </div>
          <div className="store_btn_box">
            <div className="store_btn">
                <img
                  src={require("../../images/trainee/download_ios_store.svg").default}
                  alt="apple store btn"
                  onClick={()=>appStoreClick("ios")}
                />
            </div>

            <div className="store_btn">
                <img
                  src={require("../../images/trainee/download_aos_store.svg").default}
                  alt="google store btn"
                  onClick={()=>appStoreClick("aos")}
                />
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={()=>window.open("http://youngmd.org")}
        className="with banner"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/trainee/main_with_img.png)`,
          backgroundColor: "#00B9C5",
          backgroundPosition: "92% 50%",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="content_box">메디스태프와 대한전공의협의회가 함께합니다.</div>
      </div>

      {/* 모바일 전용 */}
      <div
        onClick={()=>window.open("http://youngmd.org")}
        className="mobile_with banner"
        style={{
          backgroundColor: "#00B9C5",
        }}
      >
        <div className="content_box">메디스태프와 대한전공의협의회가 함께합니다.</div>
      </div>
    </div>
  )
}
export default TraineeBottom;