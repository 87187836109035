import {createContext} from 'react';

// 현재 open된 modal들을 나타냄
export const ModalStateContext = createContext(
  {
    state : {openedModals:[]},
    actions:{
      setOpenedModals:()=>{}
    }
  }
);

// 모달을 열고 닫는 함수
export const ModalDispatchContext = createContext({
  open:()=>{},
  close:()=>{},
  termsOfService:'', // 이용약관
  privacyPolicy:'', // 개인정보 처리 방침
});

