import React from 'react';
import './features.scss';
import useMoveScroll from "../test/useMoveScroll";

function Features() {
  const scrollItems = [
    useMoveScroll(),
    useMoveScroll(),
    useMoveScroll(),
    useMoveScroll(),
  ];
 // ******************************************
  return (
    <div id="features">
      <div id="contents">
        <div className="sub_header bg2">
          <div className="box">
            <div className="main_title sub">
              Features
            </div>
          </div>
        </div>

        <div className="sub_tab_box sub2">
          <div className="box">
            <div className="sub_tab" onClick={scrollItems[0].onMoveToElement}>
              <img src={require('../images/sub_tab_icon2_1.svg').default} alt="icon"/>
              <p>채팅을 안전하게</p>
            </div>

            <div className="sub_tab" onClick={scrollItems[1].onMoveToElement}>
              <img
                src={require('../images/sub_tab_icon2_2.svg').default}
                alt="icon"
              />
              <p>커뮤니티(모임)을 다양하게</p>
            </div>
            <div className="sub_tab" onClick={scrollItems[2].onMoveToElement}>
              <img
                src={require('../images/sub_tab_icon2_3.svg').default}
                alt="icon"
              />
              <p>의료정보를 한 곳에</p>
            </div>
          </div>
        </div>

        <div className="full_box bg_w" id="Safety" ref={scrollItems[0].element}>
          <div className="box">
            <div className="con_title">
              채팅을 안전하게
              <div className="title_line"></div>
            </div>
            <div className="sub_title_text color_b box_px">
              <div className="app_num_box m_top_ma" >1</div>
              <img
                src={require('../images/sub_con_l_icon.png')}
                alt="icon"
              />
              메시지를 보호하는 기술
            </div>
            <div className="sub_con_text app">
              시그널 프로토콜의 Double Ratchet과 X3DH key exchange 원리를 이용한 종단간 암호화(End to End Encryption) 기술로 모든 메세지를 암호화하여 본인과 상대방 외 제3자의 접근을 막습니다.
              메디스태프 자체적으로도 메시지나 파일 복호화가 불가하여 모든 사용자들은 서비스를 안전하게 이용할 수 있습니다.
            </div>
            <div className="app_bottom_img">
              <img
                src={require('../images/app_bottom_img1.png')}
                alt="icon"
              />
            </div>
          </div>
        </div>

        <div className="full_box pa_bottom_0">
          <div className="box">
            <div className="sub_title_text color_b app">
              <div className="app_num_box">2</div>
              <img

                src={require('../images/sub_con_l_icon.png')}
                alt="icon"
              />
              무분별한 캡처를 막는 워터마크 삽입
            </div>
            <div className="sub_con_text app">
              안드로이드는 캡처가 안되며 아이폰은 핸드폰 번호로 워터마크가 자동 삽입되어 의료 및 개인 정보를 유출하는 무분별한 캡처를 막았습니다.
            </div>
            <div className="app_bottom_sh">
              <div className="frame big">
                <img
                  className="img"
                  src={require('../images/iphone_frame.svg').default}
                  alt="icon"
                />
              </div>
              <div className="pic big">
                <img
                  src={require('../images/app_bottom_img2_20221208.jpg')}
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="full_box bg_w">
          <div className="box">
            <div className="sub_title_text color_b app">
              <div className="app_num_box">3</div>
              <img
                src={require('../images/sub_con_l_icon.png')}
                alt="icon"
              />
              암호화 메시지 자동 삭제
            </div>
            <div className="sub_con_text app">
              모든 메시지뿐만 아니라 서버의 기록까지도 자동으로 삭제되어 의사선생님의 안전한 채팅을 돕습니다.
            </div>
            <div className="app_bottom_img">
              <img
                src={require('../images/app_bottom_img3.png')}
                alt="icon"
              />
            </div>
          </div>
        </div>

        <div className="sub_con_in_img app">
          <img
            src={require('../images/sub_con2_in_img1.jpg')}
            alt="icon"
          />
        </div>

        <div className="full_box pa_bottom_0" id="Community"  ref={scrollItems[1].element}>
          <div className="box">
            <div className="con_title">
              커뮤니티(모임)를 다양하게
              <div className="title_line"></div>
            </div>

            <div className="sub_con_app_l_box r_box">
              <div className="sub_title_text img_r">
                익명게시판
              </div>
              <div className="sub_con_text app">
                ‘의사판 블라인드’로 완벽한 익명성을 제공합니다. 사용자를 고려한 개별 게시판(의사/의대생/군의관/공보의)에서 자유롭게 의견을 나눠보세요.
              </div>
            </div>
            <div className="sub_con_app_l_box">
              <div className="app_bottom_sh">
                <div className="frame">
                  <img
                    src={require('../images/iphone_frame.svg').default}
                    alt="iphone frame svg"
                  />
                </div>
                <div className="pic">
                  <img
                    src={require('../images/capture/board_phone_capture.jpg')}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="full_box bg_w pa_bottom_top_0">
          <div className="box">
            <div className="sub_con_app_l_box l_box">
              <div className="sub_title_text img_r">
                클럽
              </div>
              <div className="sub_con_text app">
                누구나 운영자가 되어 관심사별로 모임방을 만들어 소통할 수 있습니다. 동창 모임, 진료과별 모임, 각종
                취미 모임 등을 망설이지 마시고 만들어보세요.
              </div>
            </div>
            <div className="sub_con_app_l_box r_box">
              <div className="app_bottom_sh">
                <div className="frame">
                  <img
                    src={require('../images/iphone_frame.svg').default}
                    alt="icon"
                  />
                </div>
                <div className="pic">
                  <img
                    src={require('../images/capture/club_phone_capture.jpg')}
                    alt="club phone capture"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="full_box pa_bottom_0" id="real_name_club">
          <div className="box">
            <div className="sub_con_app_l_box r_box">
              <div className="sub_title_text img_r">
                실명 클럽 채팅
              </div>
              <div className="sub_con_text app">
                동일한 관심사를 가진 사람들과 실명 클럽에서 언제든지 자유롭게 이야기하며 소통할 수 있어요
              </div>
            </div>
            <div className="sub_con_app_l_box">
              <div className="app_bottom_sh">
                <div className="frame">
                  <img
                    src={require('../images/iphone_frame.svg').default}
                    alt="iphone frame svg"
                  />
                </div>
                <div className="pic">
                  <img
                    src={require('../images/capture/main_sl_img6.jpg')}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sub_con_in_img app">
          <img
            src={require('../images/sub_con2_in_img2.jpg')}
            alt="club phone capture"
          />
        </div>

        <div className="full_box pa_bottom_0" id="Information" ref={scrollItems[2].element}>
          <div className="box">
            <div className="con_title">
              의료정보를 한 곳에
              <div className="title_line"></div>
            </div>
            <div className="sub_con_app_l_box r_box">
              <div className="sub_title_text img_r">
                의료 뉴스
              </div>
              <div className="sub_con_text app">
                실시간으로 업데이트되는 메디스태프 채널에서 최신 의료 뉴스 및 트렌드를 만나보세요.
              </div>
            </div>
            <div className="sub_con_app_l_box">
              <div className="app_bottom_sh">
                <div className="frame">
                  <img
                    src={require('../images/iphone_frame.svg').default}
                    alt="iphone frame"
                  />
                </div>
                <div className="pic">
                  <img
                    src={require('../images/capture/news.jpg')}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="full_box bg_w pa_bottom_top_0">
          <div className="box">
            <div className="sub_con_app_l_box l_box">
              <div className="sub_title_text img_r">
                수련병원평가
              </div>
              <div className="sub_con_text app">
                메디스태프는 대한전공의협의회와 함께 더 나은 수련환경을 만들고자 전국 전공의 병원평가 설문조사 결과를 공시합니다.<br/>
                월급, 주간 평균 근무시간 등 메디스태프에서만 제공하는 ‘수련병원평가 5개년 트렌드 분석’도 살펴보세요.
              </div>
            </div>
            <div className="sub_con_app_l_box r_box">
              <div className="app_bottom_sh">
                <div className="frame">
                  <img
                    src={require('../images/iphone_frame.svg').default}
                    alt="icon"
                  />
                </div>
                <div className="pic">
                  <img
                    src={require('../images/capture/training_hospital.jpg')}
                    alt="iphone frame"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Features;
