import React, {useContext, useState} from 'react';
import {
  ModalDispatchContext,
  ModalStateContext
} from "../../components/modal/ModalContext";
import Modal from "../../components/modal/Modal";
import {traineeBottomAccordionClick} from "../../utils/common";
import {CommonContext} from "../../contexts/CommonContext";

function BottomAccordion({id, contents}) {

  const {open} = useContext(ModalDispatchContext)
  const {state} = useContext(ModalStateContext)

  const openModal = (e) => {
    let userAgent = navigator.userAgent;
    if (userAgent.match(/iPhone|iPad|iPod/)) { // 아이폰의 경우
      window.open(process.env.REACT_APP_MEDISTAFF_IOS_APP_STORE);
    } else if (userAgent.match(/android/)) { // 안드로이드의 경우
      window.open(process.env.REACT_APP_MEDISTAFF_AOS_APP_STORE);
    } else { // 웹의 경우
      open(e.target.id);
    }
  }

  const traineeBottomAccordionClick = (e) => {
    window.aws = e;
    e.preventDefault();
    const currentTarget = e.currentTarget.parentElement
    let bbb = currentTarget.querySelector(".accordion_content_box").style.maxHeight;

    if(bbb === "0px" || bbb === ""){
      currentTarget.querySelector(".accordion_content_box").style.maxHeight = "200px";
      currentTarget.querySelector("img").style.transform = "scaleY(-1)";
    }else{
      currentTarget.querySelector(".accordion_content_box").style.maxHeight = "0px";
      currentTarget.querySelector("img").style.transform = "scaleY(1)";
    }
  }

  // 질문 내역이 없을 경우
  if(contents.length === 0) return (
    <div className="data_source" style={{marginTop:"30px"}}>
      데이터출처 : 대한전공의협의회
      <p className="copyright">본 자료의 저작권은 대한전공의협의회에 있으며, 메디스태프 이외 곳에 게재 및 배포를 금지합니다.</p>
      <Modal
        id="appStoreModal"
        openedModals={state.openedModals}
        header="제목"
        type="image"
      >
        <ImageModalDesign/>
      </Modal>
    </div>
  )
  // ******************************************
  return (
    <div className="bottom_accordion_box">
      {/*<AccordionTest/>*/}
      <ul className="bottom_accordion">
        {contents.map((item, index) => (

          <li key={index} className="accordion_item">
            <div className="accordion_title_box" onClick={(e) => traineeBottomAccordionClick(e)}>
              {/*<div className="title_id">[{id}]</div>*/}
              <div className="title_content">[{id}] {item}</div>
              <img src={require('../../images/icons/accordion_icon_v2.svg').default} alt="icon"/>
            </div>

            <div className="accordion_content_box">
              <div>
                <p>세부 문항에 대한 결과는 메디스태프 모바일 어플리케이션에서 확인하실 수 있습니다.</p>
                <button
                  id="appStoreModal"
                  className="blue_btn"
                  onClick={openModal}
                  type="button"
                >
                  결과 확인하기
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <div className="data_source">
        데이터출처 : 대한전공의협의회
        <p className="copyright">본 자료의 저작권은 대한전공의협의회에 있으며, 메디스태프 이외 곳에 게재 및 배포를 금지합니다.</p>
      </div>

      <Modal
        id="appStoreModal"
        openedModals={state.openedModals}
        header="제목"
        type="image"
      >
        <ImageModalDesign/>
      </Modal>
    </div>
  )
}

export default BottomAccordion;

// ***************************************************************************************
export function ImageModalDesign() {
  const { lastYear } = useContext(CommonContext);
  return (
    <div
      className="image_modal_content"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/trainee/main_store_img.svg)`,
        backgroundSize: "588px 100%",
        backgroundPosition: "100px 152px",
        backgroundRepeat: "no-repeat"
      }}
    >
      <div className="content_box">
        <div className="title">
          메디스태프에서<br/>{lastYear} 전국 전공의 병원평가 결과를 확인하세요
        </div>
        <div className="store_box">
          <div className="store_btn">
            <img
              src={process.env.PUBLIC_URL + '/images/icon/ios_store_btn.svg'}
              alt="ios store btn"
              onClick={() => window.open(
                process.env.REACT_APP_MEDISTAFF_IOS_APP_STORE)}
            />
          </div>
          <div className="store_btn">
            <img
              src={process.env.PUBLIC_URL + '/images/icon/aos_store_btn.svg'}
              alt="aos store btn"
              onClick={() => window.open(
                process.env.REACT_APP_MEDISTAFF_AOS_APP_STORE)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}