import React from 'react';
import ImageSlider from "../../components/ImageSlider";

function MainBottom() {
 // ******************************************
  const commonUrl = "/images/capture/";
  const imageList = [
    {
      url:commonUrl+"main_slide_img1.jpg",
      alt:"초빙 화면",
    },
    {
      url:commonUrl+"board_phone_capture.jpg",
      alt:"게시판 화면",
    },
    {
      url:commonUrl+"main_sl_img3_20221208.jpg",
      alt:"채팅 화면",
    },
    {
      url:commonUrl+"main_sl_img4_20221208.jpg",
      alt:"병원평가 화면",
    },
    {
      url:commonUrl+"club_phone_capture.jpg",
      alt:"club screen",
    },
  ]

  return (
    <article className="full_box bg_w main">
      {/*<img src={require(commonUrl+"main_slide_img4.jpg")} alt="a"/>*/}
      <div className="box main">
        <div id="aaa" className="con_title left">
          <div id="bbb">
            <span
              id="ccc"
              className="sub_title_text color_b">
             의사들의 커뮤니케이션 플랫폼
            </span>
            <span>MEDISTAFF</span>
          </div>

          <div id="qr_image_box">
            <a href='http://m.site.naver.com/11gki'>
              <img
                src='https://qrcodethumb-phinf.pstatic.net/20220916_128/1663303088406XEeTT_PNG/11gki.png'
                alt="메디스태프 QR 이미지"
              />
            </a>
          </div>
        </div>

        <div className="main_app_s">
          <div className="btn bg_app_i">
            <a href="https://itunes.apple.com/us/app/메디스태프/id1353003728?l=ko&ls=1&mt=8"
              target="_blank"></a>
          </div>
          <div className="btn bg_app_g">
            <a href="https://play.google.com/store/apps/details?id=com.org.medistaffapp"
              target="_blank"></a>
          </div>
        </div>

        {/* 메인 슬라이더 */}
        <ImageSlider imageList={imageList}/>
      </div>
    </article>
  );
};
export default MainBottom;