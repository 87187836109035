import React, {useEffect, useRef} from 'react';
import MainCard from "../components/MainCard";
import {ReactComponent as LoudSpeakerIcon} from "../images/icons/loud_speaker.svg";
import {ReactComponent as Convenience} from "../images/icons/main_con_icon4.svg";
import {ReactComponent as ChatIcon} from "../images/icons/chat_icon.svg";
import LockIcon from "../images/icons/lock_icon.svg";
import RoadMap from "../components/RoadMap";
import "./ourstory.scss"
import $ from "jquery";
import {accordionClick} from "../utils/common";
import useMoveScroll from "../test/useMoveScroll";

function OurStory() {
  const scrollItems = [
    useMoveScroll(),
    useMoveScroll(),
    useMoveScroll(),
    useMoveScroll(),
  ];
  // ******************************************
  return (
    <div id="our_story">
      <div id="contents">
        <div className="sub_header bg1">
          <div className="box">
            <div className="main_title sub">
              Our Story
            </div>
          </div>
        </div>

        <div className="sub_tab_box">
          <div className="box">
            <div className="sub_tab" onClick={scrollItems[0].onMoveToElement}>
              <img src={require('../images/sub_tab_icon1_1.svg').default} alt="icon"/>
              <p>Brand Mission</p>
            </div>
            <div className="sub_tab" onClick={scrollItems[1].onMoveToElement}>
              <img src={require('../images/sub_tab_icon1_2.svg').default} alt="icon"/>
              <p>Brand Value</p>
            </div>
            <div className="sub_tab" onClick={scrollItems[2].onMoveToElement}>
              <img src={require('../images/sub_tab_icon1_3.svg').default} alt="icon"/>
              <p>Who we look for</p>
            </div>
            <div className="sub_tab" onClick={scrollItems[3].onMoveToElement}>
              <img src={require('../images/sub_tab_icon1_4.svg').default} alt="icon"/>
              <p>Roadmap</p>
            </div>
          </div>
        </div>

        <div className="full_box bg_w sub1" id="BrandMission" ref={scrollItems[0].element}>
          <div className="box">

            <div className="con_title"
               onClick={(e) => accordionClick(e)}
            >
              Brand mission
              <div className="accordian_icon">
                <img src={require('../images/accordion_icon.svg').default} alt="icon"/>
              </div>
              <div className="title_line"></div>
            </div>

            <div
              className="mo_sub1_accordion"
              // style={{display:"none"}}
            >
              <div className="sub_title_text">
                <img src={require('../images/sub_con_l_icon.png')} alt="icon"/>
                Better Security, Better Network, Better Healthcare
              </div>
              <div className="sub_con_text">
                의사선생님들의 가치를 최우선으로 생각하는 메디스태프는 보안 플랫폼을 기반으로 선생님 간의 소통 및 협력을 독려하며
                공유를 통한 가치 창조와 신뢰를 바탕으로 한 더 나은 헬스케어를 만들어 가고자 합니다.
              </div>
            </div>
          </div>
        </div>

        <article className="full_box sub1" id="BrandValue" ref={scrollItems[1].element}>
          <div className="box">

            <div className="con_title"
                 onClick={(e) => accordionClick(e)}
            >
              Brand value
              <div className="accordian_icon">
                <img src={require('../images/accordion_icon.svg').default} alt="icon"/>
              </div>
              <div className="title_line"></div>
            </div>

            <div
              className="mo_sub1_accordion"
              // style={{display:"none"}}
            >
              <MainCard
                count={4}
                subject="보안 (Security)"
                content="강력한 보안환경을 제공하여 의사분들을 보호합니다."
              ><img src={LockIcon} alt="잠금 아이콘"/>
              </MainCard>
              <MainCard
                count={4}
                subject="네트워크 (Network)"
                content="안전한 환경 속에서 의사분들이 소통하고 협력할 수 있도록 합니다."
              ><ChatIcon/></MainCard>
              <MainCard
                count={4}
                subject="신뢰 (Trust)"
                content="의사분들의 신뢰를 최우선으로 생각하는 의료전문 플랫폼이 되도록 항상 노력합니다."
              ><LoudSpeakerIcon/></MainCard>
              <MainCard
                count={4}
                subject="편의 (Convenience)"
                content="의사분들에게 맞춤형 정보를 전달하여 단순 커뮤니티 기능을 넘어 편의성을 전달합니다."
              ><Convenience/></MainCard>
            </div>
          </div>
        </article>

        <div className="sub_con_in_img">
          <img src={require('../images/sub_con_in_img1.jpg')} alt="icon"/>
        </div>

        <div className="full_box bg_w sub1" id="WhoWeLookFor" ref={scrollItems[2].element}>
          <div className="box">
            <div
              className="con_title"
                 onClick={(e) => accordionClick(e)}
            >
              Who we look for
              <div className="accordian_icon">
                <img src={require('../images/accordion_icon.svg').default} alt="icon"/>
              </div>
              <div className="title_line"></div>
            </div>
            <div
              className="mo_sub1_accordion"
              // style={{display:"none"}}
            >
              <div className="sub_con_text left_pa">
                <div className="line_box_left">
                  <div className="left_icon">M</div>
                  <div className="text_box">
                    <div className="title">Masterful</div>
                    자신이 가지고 있는 지식과 전문성을 바탕으로 수많은 도전 속에서 최고가 되기 위해 노력하는 사람입니다.
                  </div>
                </div>
                <div className="line_box_left">
                  <div className="left_icon">E</div>
                  <div className="text_box">
                    <div className="title">Endurance</div>
                    성공의 열쇠가 구성원 모두의 헌신과 노력이라 생각하며, 목표 달성을 위해 근성과 투지를 가지고 인내할 수 있는 사람입니다.
                  </div>
                </div>
                <div className="line_box_left">
                  <div className="left_icon">S</div>
                  <div className="text_box">
                    <div className="title">Self-Innovative</div>
                    헬스케어 영역을 변화시키는 일에 열정을 가지고, 가장 혁신적인 기업을 만들고자 노력하는 사람입니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sub_con_in_img">
          <img src={require('../images/sub_con_in_img2.jpg')} alt="icon"/>
        </div>
        <RoadMap scrollItems={scrollItems}/>
      </div>
    </div>
  );
};
export default OurStory;
