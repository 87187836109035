import React, { useContext } from 'react';
import './header.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { ModalDispatchContext } from 'components/modal/ModalContext';
import classnames from 'classnames';
import RightArrow from '../../images/rightArrow.svg';

let currentPath = '-';
function Header({ type }) {
  const navigate = useNavigate();
  const { open } = useContext(ModalDispatchContext);
  const openModal = (e) => {
    open(e.target.id);
  };
  // 모바일 햄버거 버튼 클릭시
  function goMobileTab() {
    if ($('.top_menu').css('display') == 'none') {
      $('.slick-dots').css('display', 'none');
      $('.top_menu').fadeIn('fast');
      $('.top_icon').fadeIn('fast');
      $('.top_menu_bg').fadeIn('fast');
      $('body').css('overflow', 'hidden');

      // 모바일 햄버거 버튼 닫기를 클릭하면
      $('.tab_close_btn').click(function () {
        $('.slick-dots').css('display', 'block');
        $('.top_menu').fadeOut('fast');
        $('.top_icon').fadeOut('fast');
        $('.top_menu_bg').fadeOut('fast');
        $('body').css('overflow', 'auto');
      });
    }
  }

  // 현재 선택한것과 같은 경로를 선택했을때-
  const location = useLocation();
  function handleLinkClick(event) {
    if (event.currentTarget.pathname === location.pathname) {
      navigate(location.pathname);
    }
  }

  // ******************************************
  return (
    <header
      id="header"
      className={classnames({ error_header: type === 'error' })}
    >
      <Link to="/">
        <div className="top_logo"></div>
      </Link>

      <div className="tab_btn" onClick={goMobileTab}></div>
      <div className="top_menu_bg"></div>

      <div className="top_menu" id="top_menu">
        <div className="tab_close_btn"></div>

        <ul>
          <li>
            <Link to="/ourstory" onClick={handleLinkClick}>
              OUR STORY
            </Link>
          </li>
          <li>
            <Link to="/features" onClick={handleLinkClick}>
              FEATURES
            </Link>
          </li>
          <li>
            <Link to="/career" onClick={handleLinkClick}>
              CAREER
            </Link>
          </li>
          <li>
            <Link to="/survey" onClick={handleLinkClick}>
              SURVEY
            </Link>
          </li>
          <li>
            <Link to="/press" onClick={handleLinkClick}>
              PRESS
            </Link>
          </li>
          <li>
            <Link to="/notice" onClick={handleLinkClick}>
              NOTICE
            </Link>
          </li>
          <li>
            <Link to="/trainee" onClick={handleLinkClick}>
              TRAINEE
            </Link>
          </li>
        </ul>

        <div className="top_icon sm:mr-20 !pb-100 sm:pb-0">
          <div
            className="cont_box contact_btn"
            onClick={openModal}
            id="contactUsModal"
          >
            CONTACT US
          </div>
          <div
            className="cont_box contact_btn conf_cs_btn "
            onClick={() => window.open('https://pco.medistaff.co.kr')}
          >
            학술대회 문의하기
            <img src={RightArrow} className="sm:hidden ml-16" />
          </div>
          <div className="sm:hidden w-full">
            <div className="app_box">
              <a
                href="https://play.google.com/store/apps/details?id=com.org.medistaffapp"
                target="_blank"
                className="!h-48"
              >
                <div className="icon bg_app_g"></div>
              </a>
              <a
                href="https://itunes.apple.com/us/app/메디스태프/id1353003728?l=ko&ls=1&mt=8"
                target="_blank"
                className="!h-48"
              >
                <div className="icon bg_app_i"></div>
              </a>
            </div>
            <div className="mt-32 flex text-right w-full">
              <a
                href="https://www.instagram.com/medistaff_inc?igsh=bWlhZGw3bmFhMnNv"
                target="_blank"
                className="!h-32"
              >
                <div className="bg_instagram w-32 h-32"></div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="scroll_top">
        <img
          src={require('../../images/icons/scroll_top_icon.svg').default}
          alt="상단으로 이동 아이콘"
        />
      </div>
    </header>
  );
}
export default Header;
