import './common.scss';
import "./pages/trainee/contents/content.scss";

import "./pages/layout/footer.scss"
import TraineeTobNav from "./pages/trainee/TraineeTobNav";
import TraineeMainNav from "./pages/trainee/TraineeMainNav";
import React from "react";
import {Outlet} from "react-router-dom";
import TraineeBottom from "./pages/trainee/TraineeBottom";
import Footer from "./pages/layout/Footer";
import ScrollToTop from "./components/ScrollToTop";
import TraineeMainNavTwo from "./pages/trainee/TraineeMainNavTwo";


function TraineeApp() {
  return (
    <>
      <ScrollToTop/>
        <div id="TraineeApp">
          <TraineeTobNav/>
          {/*<TraineeMainNav/>*/}
          <TraineeMainNavTwo/>
          <Outlet/>
          <TraineeBottom/>
          <Footer/>
        </div>
    </>
  );
}

export default TraineeApp;