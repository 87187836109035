import React from 'react';
import "./trainee_top_nav.scss"

function Trainee() {
 // ******************************************
  return (
    <header id="trainee_header">
      <div className="top_menu">
        <div className="top_logo">
            <a href="/" style={{display:"inline-block"}}>
              <img src={require("../../images/icons/medistaff_icon.svg").default} alt="medistaff icon"/>
            </a>
          </div>

          <ul>
            <li>
              <a href="https://www.facebook.com/medistaff.kr/" target="_blank">
                <img src={require("../../images/icons/facebook_icon.svg").default} alt="facebook icon"/>
              </a>
            </li>
            <li>
              <a href="https://blog.naver.com/medistaff" target="_blank">
                <img src={require("../../images/icons/blog_icon.svg").default} alt="blog icon"/>
              </a>
            </li>
            <li>|</li>
            <li>
              <a
                href="https://itunes.apple.com/us/app/메디스태프/id1353003728?l=ko&ls=1&mt=8"
                target="_blank">
                <img src={require("../../images/top_app_ios_icon.svg").default} alt="app store"/>
              </a>
            </li>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.org.medistaffapp"
                target="_blank">
                <img src={require("../../images/top_app_and_icon.svg").default} alt="google play"/>
              </a>
            </
            li>
          </ul>
      </div>

      <div className="scroll_top">
        <img
          src={require('../../images/icons/scroll_top_icon.svg').default}
          alt="상단으로 이동 아이콘"/>
      </div>
    </header>
  );
}
export default Trainee;