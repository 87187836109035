import $ from "jquery";
import React, {useContext} from 'react';
import {ModalDispatchContext} from "../components/modal/ModalContext";

function bodyAuto(){
  $('body').css('overflow','auto');
}
function bodyHid(){
  $('body').css('overflow','hidden');
}

//링크공유버튼 클릭시
function urlCopyBtnClick(){
  let dummy   = document.createElement("input");
  let text    = window.location.href;

  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
  alert("링크가 클립보드에 복사되었습니다.");
}

function appStoreClick(_type) {
  if(_type === "ios")
    window.open(process.env.REACT_APP_MEDISTAFF_IOS_APP_STORE);
  else
    window.open(process.env.REACT_APP_MEDISTAFF_AOS_APP_STORE);
}

// type이 없는 경우
const moveMobileAppStore = (e) => {
  let userAgent = navigator.userAgent;
  console.log(userAgent);
  if (userAgent.match(/iPhone|iPad|iPod/)) { // 아이폰의 경우
    window.open(process.env.REACT_APP_MEDISTAFF_IOS_APP_STORE);
  } else if (userAgent.match(/Android/)) { // 안드로이드의 경우
    window.open(process.env.REACT_APP_MEDISTAFF_AOS_APP_STORE);
  }
}

export {bodyAuto, bodyHid,urlCopyBtnClick, appStoreClick, moveMobileAppStore};

$(document).ready(function(){
  //스크롤 이벤트

  $(window).scroll(function() {
    //스크롤 하단으로 내릴떄 header 변경a
    if ( $( this ).scrollTop() > 70 ) {
      $('#header').addClass('header_scroll');
    } else {
      $('#header').removeClass('header_scroll');
    }

    //스크롤 하단으로 내릴때 버튼 보이기
    if ( $( this ).scrollTop() > 200 ) {
      $('.scroll_top').fadeIn();
    } else {
      $('.scroll_top').fadeOut();
    }
  });

  //sub1,2 pc버전 세부메뉴 스크롤
  $('.sub_tab a').click( function() {
    $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
  });

  //최상단 이동 버튼
  $('.scroll_top').click( function() {
    $( 'html, body' ).animate( { scrollTop : 0 }, 400 );
    return false;
  });
});

// 아코디언 메뉴 클릭시
export const accordionClick = (e) => {
  e.preventDefault();
  const currentTarget = e.target.parentElement;
  let bbb = currentTarget.querySelector(".mo_sub1_accordion").style.maxHeight;
  // console.log(bbb);
  let windowWidth = $( window ).width();
  if(windowWidth > 480) return false;

  if(bbb === "0px" || bbb === ""){
    // currentTarget.querySelector(".mo_sub1_accordion").style.height
    currentTarget.querySelector(".mo_sub1_accordion").style.maxHeight = "3500px";
    currentTarget.querySelector("img").style.transform = "scaleY(-1)";
  }else{
    currentTarget.querySelector(".mo_sub1_accordion").style.maxHeight = "0px";
    currentTarget.querySelector("img").style.transform = "scaleY(1)";
  }
}

// 현재 날짜에서 해당 day를 더한 시기를 반환
export const addDaysToDate = (day) => {
  const date = new Date();
  date.setDate(date.getDate() + day);
  return date;
};


