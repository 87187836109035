import React, { useContext } from 'react';
import { insertQuestion } from '../../api/api';
import 'pages/layout/footer.scss';
import { ModalDispatchContext } from '../modal/ModalContext';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

// 문의하기 홈페이지
export default function ContactUs({ inquiryType }) {
  const { open, allClose } = useContext(ModalDispatchContext);
  const openModal = (e) => {
    open(e.target.id);
  };
  // ***********
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      inquiryType: inquiryType || 'NO',
    },
  });

  const InquiryTypeMap = [
    { value: 'NO', display: '일반 문의' },
    { value: 'AD', display: '광고 문의' },
    { value: 'SU', display: '설문 조사 문의' },
    { value: 'CAREER', display: '초빙 문의' },
  ];

  const onSubmit = (data) => {
    console.log('data.inquiryType', data.inquiryType);
    allClose();
    insertQuestion(data)
      .then((r) => {
        const inquiry = InquiryTypeMap.find(
          (item) => item.value === data.inquiryType,
        );
        if (inquiry) {
          alert(`${inquiry.display}가 완료되었습니다.`);
        } else {
          alert('유효하지 않은 문의 유형입니다.');
        }
      })
      .catch((r) => alert('이메일 전송에 실패하였습니다.'));
  };
  const checkKeyDown = (e) => {
    if (e.code === 'Enter') {
      if (e.target.tagName === 'TEXTAREA') {
        return; // 기본 동작(줄 바꿈) 허용
      }
      e.preventDefault();
    }
  };
  // *****************************************************
  return (
    <>
      <article id="contact_popup" className="popup_content">
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="guide">
            의사 전용 ‘메디스태프’(Medistaff) 어플리케이션은 구글 플레이
            스토어와 앱 스토어에서 다운이 가능하며, 모바일 앱 내에서 가입 승인
            요청을 주시면 됩니다. (홈페이지 가입 불가) 기타 문의 사항의 경우,
            메일 부탁드립니다 :)
          </div>

          <div className="input_box">
            <label htmlFor="inquiryType">
              Type
              <span className="label_sub_box">
                {!!inquiryType
                  ? ''
                  : '(일반 문의 / 광고 제휴 / 설문조사 / 초빙 문의)'}
              </span>
              <span className="essential">*</span>
            </label>
            <select
              id="inquiryType"
              {...register('inquiryType', {
                required: '타입을 선택해주세요.',
              })}
            >
              <option value="NO">일반 문의</option>
              <option value="AD">광고 제휴</option>
              <option value="SU">설문 조사</option>
              <option value="CAREER">초빙 문의</option>
            </select>
          </div>

          <div className="input_box">
            <label htmlFor="name">
              Name<span className="essential">*</span>
            </label>
            <input
              type="text"
              placeholder="이름을 한 글자 이상 입력하세요"
              className={classnames({ error: errors.name })}
              {...register('name', {
                required: '이름을 한 글자 이상 입력하세요',
              })}
              onBlur={() => trigger('name')}
            />
            {errors.name && (
              <div className="error_message">{errors.name.message}</div>
            )}
          </div>

          <div className="input_box">
            <label htmlFor="email">
              E-mail<span className="essential">*</span>
            </label>
            <input
              type="text"
              name="email"
              placeholder="메일형식 텍스트를 입력하세요."
              className={classnames({ error: errors.email })}
              {...register('email', {
                required: '메일형식 텍스트를 입력하세요.',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                  message: '올바른 메일형식이 아닙니다.',
                },
              })}
              onBlur={() => trigger('email')}
            />
            {errors.email && (
              <div className="error_message">{errors.email.message}</div>
            )}
          </div>

          <div className="input_box">
            <label htmlFor="content">
              {' '}
              Feedback or Question<span className="essential">*</span>
            </label>
            <textarea
              placeholder="내용을 한 글자 이상 입력하세요"
              rows={5}
              className={classnames({ error: errors.content })}
              {...register('content', {
                required: '내용을 한 글자 이상 입력하세요',
              })}
              onBlur={() => trigger('content')}
            ></textarea>
            {errors.content && (
              <div className="error_message">{errors.content.message}</div>
            )}
          </div>

          <div className="input_box checkbox">
            <input
              type="checkbox"
              name="privacy_checkbox"
              id="privacy_checkbox"
              className={classnames({ error: errors.privacy_checkbox })}
              {...register('privacy_checkbox', {
                required: '개인정보 처리방침은 반드시 체크하셔야 합니다.',
              })}
            />
            <label
              htmlFor="privacy_checkbox"
              className="privacy_checkbox_label"
            >
              <span
                className="privacyPopup"
                onClick={openModal}
                id="policyModal"
              >
                개인정보 처리방침
              </span>
              을 읽고 이에 동의합니다.(필수<span className="essential">*</span>)
            </label>
          </div>
          {errors.privacy_checkbox && (
            <div className="error_message">
              {errors.privacy_checkbox.message}
            </div>
          )}

          <div className="btn_box">
            <button type="submit">SUBMIT</button>
          </div>
        </form>
      </article>
    </>
  );
}
