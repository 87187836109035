import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classnames from "classnames";

export default function ImageSlider({page, imageList}) {
  const settings = {
    fade: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    swipeToSlide: true,
    autoplay:true
  };
 // ******************************************
  return (
    <div className="main_app_slide">
      <div className="frame"></div>
      <div className="frame_bg"></div>
      <Slider {...settings}>
        {imageList.map((item, index)=>(
          <div
            className={classnames( {career_slider : page === "career"})}
            key={index}>
            <img src={process.env.PUBLIC_URL+item.url} alt={item.alt}/>
          </div>
        ))}
      </Slider>
    </div>
  )
}