import React from 'react';
import ReactDOM from 'react-dom/client';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'tw-elements'; // tailwind 엘리먼트 요소 사용하기
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Main from "./pages/main/Main";
import OurStory from "./pages/OurStory";
import Traninee from "./pages/trainee/contents/TraineeMain";
import TraineeMain from "./pages/trainee/contents/TraineeMain";
import Notice from "./pages/notice/Notice";
import Press from "./pages/Press";
import Career from "./pages/Career";
import Features from "./pages/Features";
import NotFound from "./pages/NotFound";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import NoticeDetail from "./pages/notice/NoticeDetail";
import Loading from "pages/layout/Loading";
import TraineeApp from "./TraineeApp";
import TraineeTrend from "./pages/trainee/contents/TraineeTrend";
import TraineeRanking from "./pages/trainee/contents/TraineeRanking";
import ResultsByHospital from "./pages/trainee/contents/ResultsByHospital";
// START ************* CHART.JS 사용하기 *************
import Chart from 'chart.js/auto';
import Error from "pages/layout/Error";
import {ContextProviders} from "./contexts/ContextProviders";
import Survey from "./pages/survey/Survey";
import {CookiesProvider} from "react-cookie"; // Actuator health 정보를 보여주는 컴포넌트
Chart.defaults.plugins.tooltip.backgroundColor = 'white';
Chart.defaults.plugins.tooltip.titleColor = 'black';
Chart.defaults.plugins.tooltip.titleFont = {
  size: 14
};
Chart.defaults.plugins.tooltip.bodyColor = 'black';
Chart.defaults.plugins.tooltip.borderColor = '#eeeef1';
Chart.defaults.plugins.tooltip.borderWidth = 1;
Chart.defaults.plugins.tooltip.cornerRadius = 5;
Chart.defaults.plugins.tooltip.padding = 10;
Chart.defaults.plugins.tooltip.displayColors = true;
Chart.defaults.plugins.tooltip.intersect = false;
Chart.defaults.plugins.tooltip.mode = 'index';
Chart.defaults.plugins.tooltip.position = 'nearest';
Chart.defaults.plugins.tooltip.animation.duration = 400;

Chart.defaults.plugins.legend.labels.boxWidth=13;
Chart.defaults.plugins.legend.labels.boxHeight=13;

// END ************* CHART.JS 사용하기 *************

const queryClient = new QueryClient();
const router = createBrowserRouter ([
  {
    path: '/',
    element: <App/>,
    errorElement: <Error errorCode={404}/>,

    children: [
      {index: true, path: "/", element: <Main/>},
      {path: '/ourstory', element: <OurStory/>},
      {path: '/features', element: <Features/>},
      {path: '/career', element: <Career/>},
      {path: '/survey', element: <Survey/>},
      {path: '/press', element: <Press/>},
      {path: '/notice', element: <Notice/>},
      {path: '/notice/:id', element: <NoticeDetail/>},
      {path: '/trainee', element: <Traninee/>},

    ]
  },
  {
    path: '/index.html',
    element: <App/>,
    errorElement: <NotFound/>,

    children: [
      {index: true, path: "/index.html", element: <Main/>}
    ]
  },
  {
    path: '/trainee',
    element: <TraineeApp/>,
    errorElement: <NotFound/>,

    children: [
      {index: true, path: "/trainee", element: <TraineeMain/>},
      {path: "/trainee/main", element: <TraineeMain/>},
      {path: '/trainee/trend', element: <TraineeTrend/>},
      {path: '/trainee/all', element: <TraineeRanking/>},
      {path: '/trainee/money', element: <TraineeRanking/>},
      {path: '/trainee/training', element: <TraineeRanking/>},
      {path: '/trainee/work', element: <TraineeRanking/>},
      {path: '/trainee/welfare', element: <TraineeRanking/>},
      {path: '/trainee/safety', element: <TraineeRanking/>},
      {path: '/trainee/hospital', element: <ResultsByHospital/>},
    ]
  },
  // {path: '/api/:id', element: <div/>},
  {path: '/loading', element: <Loading/>},
  {path: '/error/:error-code', element: <Error/>},
  // {path: '/test', element: <SliderTest/>},
  // {path: '/actuator/health', element: <Health/>},{/* Actuator health 정보를 보여주는 컴포넌트 */}
]);
// *******************************************************************

// *******************************************************************
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <ContextProviders>
        <RouterProvider router={router}/>
      </ContextProviders>
    </QueryClientProvider>
    </CookiesProvider>
  </React.StrictMode>
);

reportWebVitals();
