import React from 'react';
import MainCard from '../../components/MainCard';
import MainOneIcon from '../../images/icons/lock_icon.svg';
import { ReactComponent as MainTwoIcon } from '../../images/icons/chat_icon.svg';
import { ReactComponent as MainThreeIcon } from '../../images/icons/loud_speaker.svg';
import { moveMobileAppStore } from '../../utils/common';

function MainMiddleIntroduce() {
  // ******************************************
  return (
    <article className="box main">
      <div className="w_box">
        <div className="trainee_ad">
          <div
            className="web"
            onClick={() => window.open('https://pco.medistaff.co.kr/')}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                '/images/main/241023_conference_register_w.jpg'
              }
              alt="초빙 배너"
            />
          </div>

          <div
            className="mobile"
            onClick={() => window.open('https://pco.medistaff.co.kr/')}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                '/images/main/241023_conference_register_m.jpg'
              }
              alt="모바일 초빙 배너"
            />
          </div>
        </div>

        <div className="con_title">
          MEDISTAFF가 제공하는 것<div className="title_line"></div>
        </div>

        <div>
          <MainCard
            subject="의료정보를 한 곳에"
            content="실시간으로 접할 수 있는 의료 뉴스, 전국 전공의 병원평가, 의사 초빙까지 최신 의료 트렌드를 메디스태프 채널에서 제공해드립니다."
          >
            <MainThreeIcon />
          </MainCard>
          <MainCard
            subject="커뮤니티(모임)을 다양하게"
            content="사용자를 고려한 개별 게시판과 클럽 서비스는 학술적인 교류뿐만 아니라 최신 지견을 모을 수 있는 장을
                마련해 주며 더 나아가 취미생활까지도 나눌 수 있는 소통 공간이 되어드립니다."
          >
            <MainTwoIcon />
          </MainCard>
          <MainCard
            subject="채팅을 안전하게"
            content="메디스태프에서 주고받는 모든 메시지는 시그널 프로토콜의 종단간 암호화 기술로 암호화되어 제3자의 접근을 막으며 의사선생님의 안전한 채팅을 돕습니다."
          >
            <img src={MainOneIcon} alt="잠금 아이콘" />
          </MainCard>
        </div>
      </div>
    </article>
  );
}
export default MainMiddleIntroduce;
