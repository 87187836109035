import {ModalProvider} from "../components/modal/ModalProvider";
import {CommonContext} from "./CommonContext";

export function ContextProviders({ children }) {
  return (
    <CommonContext.Provider value={{lastYear: 2022}}>
      <ModalProvider>
          {children}
      </ModalProvider>
    </CommonContext.Provider>
  )
}