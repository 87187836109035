import React from 'react';

function NotFound() {

 // ******************************************
  return (
    <>
      <h1>잘못된 경로입니다. 해당 경로의 파일을 찾을 수 없습니다.</h1>
    </>
  );
};
export default NotFound;