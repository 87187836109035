import React, {useContext} from 'react';
import {CommonContext} from "../../../contexts/CommonContext";

function TraineeMain() {
  const { lastYear } = useContext(CommonContext);
  // ******************************************
  return (
    <>
    <div className="trainee_contents">
      <div id="trainee_main">
        <div>
          <p>
            <b>전공의가 직접 평가한 수련병원 정보</b><br/>
            메디스태프와 대한전공의협의회가 함께합니다.
          </p>
          <div className="info_text">
            <span className="b_text"><b>메디스태프</b></span>는 {lastYear}년
            대한전공의협의회에서
            수련병원에 근무하는 의사들을 대상으로 실시한 수련병원 평가 결과를 공시하고 있습니다.
            공시된 결과에 대한 상세 내용은 메디스태프 모바일 어플리케이션에서 확인하실 수 있습니다.
          </div>
        </div>

        <div className="line"></div>

        <div className="box">
          <div className="title">
            메디스태프에서만<br/>볼 수 있는 정보
          </div>
          <div className="info_chart">
            <div className="num">01</div>
            <div>
              <div>
                <img
                  src={process.env.PUBLIC_URL+"/images/trainee/main_chart_img1.svg"}
                 alt="메디스태프 로고"/>
              </div>
              <div className="desc">차트로 보여주는<br/>자세한 결과</div>
            </div>
          </div>
          <div className="info_chart">
            <div className="num">02</div>
            <div>
              <div>
                <img
                  src={process.env.PUBLIC_URL+"/images/trainee/main_chart_img2.svg"}
                  alt="메디스태프 로고"/>
              </div>
              <div className="desc"> 다양한 비교 옵션<br/>(규모, 연차)</div>
            </div>
          </div>
          <div className="info_chart">
            <div className="num">03</div>
            <div>
              <div>
                <img
                  src={process.env.PUBLIC_URL+"/images/trainee/main_chart_img3.svg"}
                  alt="메디스태프 로고"/>
              </div>
              <div className="desc">한눈에 파악할 수 있는<br/>병원 별 종합 평가</div>
            </div>
          </div>
        </div>

      </div>
      <div className="mobile_info_text">
        <span className="b_text"><b>메디스태프</b></span>는 {lastYear}년
        대한전공의협의회에서
        수련병원에 근무하는 의사들을 대상으로 실시한 수련병원 평가 결과를 공시하고 있습니다.
        공시된 결과에 대한 상세 내용은 메디스태프 모바일 어플리케이션에서 확인하실 수 있습니다.
      </div>
    </div>
    </>
  );
}

export default TraineeMain;