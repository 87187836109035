import React, {useRef} from 'react';

// 클릭하면 특정 DIV로 스크롤 이동하는 Hook 만들기
function useMoveScroll() {
  const element = useRef(null);
  const onMoveToElement = () => {
    element.current?.scrollIntoView({behavior:'smooth',block:'start'});
  }
  return {element, onMoveToElement};
}
export default useMoveScroll;


// scrollIntoView() : 해당 element로 스크롤 이동
// behavior : 애니매이션을 사용할지 말지 선택함
// block : 클릭할시 어디에 사용자 스크롤을 위치시킬 건지 정함.("start", "center", "end", "nearest")