import React from 'react';
import {Link} from "react-router-dom";

function NoticeItem({notice}) {
  if(!notice) return null;
  const dataMonth = new Date(notice.date).getFullYear() +" - "+ new Date(notice.date).getMonth();
  const nowMonth = new Date().getFullYear() +" - "+ new Date().getMonth();
  // console.log(`${dataMonth} / ${nowMonth}`)
  // 이미지 태그 일시적으로 삭제
  // let myRegExp1 =  /<IMG(.*?)>/gi;
  // let removeImgTxt = notice.notice_content.replace(myRegExp1, '');

  return (
    <div className="notice_item_box">
      <Link to={`${notice.noticeIdx}`}>
        {dataMonth === nowMonth && <div className="new_icon"></div>}
        <div className="text_box">
          <div className="title">{notice.noticeTitle}</div>
          <div className="date">{notice.date}</div>
          {/*<div*/}
          {/*  className="text"*/}
          {/*  dangerouslySetInnerHTML={ {__html: removeImgTxt} }*/}
          {/*></div>*/}
        </div>
      </Link>
    </div>
  )
}
export default NoticeItem;