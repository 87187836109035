import { Divider } from '@mui/material';
import React from 'react';

const Title = ({ title }) => {
  return (
    <div
      className="flex flex-col items-center"
      style={{ color: '#2E3448', wordBreak: 'auto-phrase' }}
    >
      <div className="font-bold text-18 md:text-30 leading-27 sm:leading-44 mb-20 md:mb-28 text-center">
        {title}
      </div>
      <Divider
        sx={{
          '&.MuiDivider-root': {
            borderWidth: 3,
            borderColor: '#2E3448',
            opacity: 0.08,
            borderTop: 0,
          },
        }}
        variant="middle"
        className="w-100 sm:w-130 mx-auto mb-20 border-2 rounded-2"
      />
    </div>
  );
};

const SubTitle = ({ title, className }) => {
  return (
    <div
      className={`text-16 text-center md:text-22 mt-40 md:mt-80 mb-14 font-bold leading-24 md:leading-32 ${className}`}
      style={{ color: '#1F4BD5', wordBreak: 'auto-phrase' }}
      dangerouslySetInnerHTML={{ __html: title }}
    ></div>
  );
};

const TextContent = ({ children }) => {
  return (
    <div
      className="text-14 leading-24 md:text-17 md:leading-28 opacity-70 text-center break-words max-w-800"
      style={{ color: '#2E3448', wordBreak: 'auto-phrase' }}
    >
      {children}
    </div>
  );
};

export { Title, SubTitle, TextContent };
