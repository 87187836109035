import React, {useState} from 'react';
import BottomAccordion from "../BottomAccordion";
import classnames from "classnames";
import {Bar, Line} from "react-chartjs-2";
import {
  commonOption,
  trendAllChartData,
  trendAllOption,
  trendAnnualChartData,
  trendBig5ChartData,
  trendBig5Option,
  trendScaleChartData,
} from "./ChartData";
import {urlCopyBtnClick} from "../../../utils/common";
import $ from "jquery";

const subject = "5개년 트렌드"
const middleSubject = "주당 평균 근무시간"
function TraineeTrend() {

  const contents = [
    "월급 실수령액",
    "주당 평균 근무시간",
    "주치의로 정규 근무 시 평균 담당환자 수",
    "당직근무 이후 휴식시간",
    "전년도 평균 실사용 휴일 수",
    "전반적 근무환경 만족도",
    "수련과 관련 없는 업무의 비중",
    "교수/상급 전공의로부터 성희롱/성폭력/언어적·신체적 폭력 피해 여부"
  ];

  const [index, setIndex] = useState(0);
  const data = [
    {
      id:0,
      title:"전체 트렌드",
      content:(
        <Line options={trendAllOption} data={trendAllChartData}/>
      )
    },
    {
      id:1,
      title:"규모 별 비교",
      content:(
        <Bar options={commonOption} data={trendScaleChartData}></Bar>
      )
    },
    {
      id:2,
      title:"Big 5 병원",
      content:(
        <Bar options={trendBig5Option} data={trendBig5ChartData}></Bar>
      )
    },
    {
      id:3,
      title:"수련연차 별 비교",
      content:(
        // trendAnnual
        <Bar options={trendBig5Option} data={trendAnnualChartData}></Bar>
      )
    },
  ]

  // 모바일의 경우
  const traineeTap = (e, item) => {
    let windowWidth = $( window ).width();
    if(windowWidth > 480){
      setIndex(item.id)
      return false;
    }

    const aaa = document.querySelectorAll(".tab_header_item");
    if (index === item.id) { // 펼치기
      document.querySelector(".accordion_icon").style.transform = `scaleY(-1)`;
      for (let i = 0; i < aaa.length; i++) {
        aaa[i].style.display = "block";
      }

    } else {
      document.querySelector(".accordion_icon").style.transform = `scaleY(1)`;
      setIndex(item.id)
      for (let i = 0; i < aaa.length; i++) {
        aaa[i].style.display = "none";
      }
      e.target.style.display = "block";
    }
  }

// ******************************************
  return (
    <>
    <article className="trainee_tap_content">
      <div id="trend" className="tab_container">
        <div>
          <ul className="tab_header_box">
            <img className="accordion_icon" src={process.env.PUBLIC_URL+"/images/icon/accordion_icon_2.svg"} alt="accordion icon"/>
            {data.map(item => (
              <li
                key={item.id}
                className={classnames("tab_header_item",{active : index === item.id })}
                onClick={(e)=>traineeTap(e, item)}
              >
                <img src={process.env.PUBLIC_URL+"/images/icon/sub_tab_in_icon.svg"} alt="active icon"/>
                {item.title}
              </li>
            ))}
          </ul>

          <div className="tab_content_box">

            <div className="url_copy_btn_box">
              <div className="url_copy_btn"
                   onClick={urlCopyBtnClick}>
                링크 공유
              </div>
            </div>

            {data.filter(item => index === item.id).map(item => (
              <div
                key={item.id}
                className="tab_content_item_box">

                <div className="tab_content_item_title">
                  <div>
                    <span>{subject}</span>
                    <b>{middleSubject}</b>
                  </div>
                  <span className="aaa">{item.title}</span>
                </div>

                {/* 실제 차트 보여주는 곳*/}
                <div className="tab_content_item_content">
                  {item.content}
                </div>
              </div>
            ))}
            <BottomAccordion
              id="트렌드"
              contents={contents}
            />
          </div>
        </div>
      </div>
    </article>
    </>
    )
  }
export default TraineeTrend;