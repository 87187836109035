import React from 'react';
import classnames from "classnames";
import $ from "jquery";
import {accordionClick} from "../utils/common";

function RoadMap({scrollItems}) {
  const laodMap = [
    {year : 2016, month: "02", title:"메디스태프 법인 설립"},
    // *****************************************************************************
    {year : null, month: "10", title:"중소기업진흥공단 청년창업기업자금 대상자 선정"},
    {year : null, month: "11", title:"서울바이오허브 스타트업 챌린지 우수상"},
    {year : 2017, month: "12", title:"청년의사 MOU 체결"},
    // *****************************************************************************
    {year : null, month: "03", title:"메디스태프 App Openbeta 출시"},
    {year : null, month: "04", title:"대한전공의협의회 MOU 체결"},
    {year : 2018, month: "05", title:"메디스태프 App 정식 출시"},
    // *****************************************************************************
    {year : null, month: "07", title:"케어랩스, 라이프시맨틱스 전략적 투자 유치"},
    {year : null, month: "08", title:"SBS 의학드라마 ‘의사요한’, 제작지원"},
    {year : null, month: "09", title:"대한전공의협의회 MOU 체결"},
    {year : 2019, month: "11", title:"전국전공의수련병원평가 서비스 오픈"},
    // *****************************************************************************
    {year : null, month: null, title:"코로나19 #의료진을응원합니다 릴레이 캠페인 진행"},
    {year : null, month: "02", title:"대한공중보건의사협의회 기부 / 대구경북지역 의료지원"},

    {year : null, month: null, title:"대한공중보건의사협의회 MOU 체결"},
    {year : null, month: "04", title:"국경없는의사회한국 MOU 체결"},

    {year : null, month: "06", title:"초빙 서비스 OPEN"},
    {year : 2020, month: "07", title:"스틸리언 MOU 체결"},
    // *****************************************************************************
    {year : null, month: "01", title:"‘2021년 전국 전공의 병원평가’ 서비스 오픈"},
    {year : null, month: "02", title:"'의사협회 선거 정보 제공' 서비스 오픈"},

    {year : null, month: null, title:"Infobank 투자 유치"},
    {year : null, month: "03", title:"오아시스엔젤클럽, AI엔젤클럽 투자 유치"},

    {year : null, month: "04", title:"BNK부산은행 ‘청년 의료인력 금융지원’ MOU 체결"},
    {year : null, month: "08", title:"휴램프로 MOU 체결"},
    {year : 2021, month: "11", title:"‘클럽’ 서비스 오픈"},
    // *****************************************************************************
    {year : null, month: "01", title:"메디컬 매버릭스 MOU 체결"},
    {year : null, month: "02", title:"‘2022 대한 공중보건의사 협의회 온라인 동계 학술대회’"},
    {year : null, month: "03", title:"에이치디정션 MOU 체결"},
    {year : null, month: "04", title:"모우다 MOU 체결"},
    {year : null, month: "07", title:"대한전공의협의회 / 대한공중보건의사협의회 MOU 체결"},
    {year : null, month: "08", title:"APP 서비스의 뉴스 언론사 변경, 뉴스 서비스 기능 고도화 등 주요 기능 업데이트"},

    {year : null, month: null, title:"키톡 서비스 신규 오픈"},
    {year : null, month: "09", title:"제이커브인베스트먼트, 프로디지인베스트먼트에서 총합 15억원 시리즈 A 투자 유치"},

    {year : null, month: "11", title:"닥터스온더클라우드 / 브랜드 본담 / 투비콘 MOU 체결"},

    {year : null, month: null, title:"통증기능분석학술대회 연수강좌 운영"},
    {year : 2022, month: "12", title:"대한응급의학의사회 학술대회 운영"},
    // *****************************************************************************
    {year : 2023, month: "01", title:"APP 서비스의 채팅 기능 출시 예정"},
  ]
  const reverseLoadMap = laodMap.reverse();
  const finalYear = reverseLoadMap[0].year;
  const finalMonth = reverseLoadMap[0].month;

 // ******************************************
  return (
    <article className="full_box roadmap_bg sub1 bottom" id="Roadmap" ref={scrollItems[3].element}>
      <div className="box">
        <div className="con_title"
             onClick={(e) => accordionClick(e)}
        >
          Road map
          <div className="accordian_icon">
            <img src={require('../images/accordion_icon.svg').default} alt="icon"/>
          </div>
          <div className="title_line"></div>
        </div>
        <div
          className="mo_sub1_accordion"
          // style={{display:"none"}}/
        >
          <div className="m_bg_bottom"></div>
          <div className="sub_title_text">
            <img src={require('../images/sub_con_l_icon.png')} alt="icon"/>
            메디스태프가 걸어가는길
          </div>

          <div className="sub_con_text left_pa">
            <div className="road_map_bg">
              {
                reverseLoadMap.map(n => (
                  <div className={classnames("road_map_in",{now : n.year === finalYear && n.month === finalMonth},{no_month:n.month === null})}>
                    {(n.year !== null && n.month !== null)
                      && <div className={classnames("year_box",{now : n.year === finalYear})}>{n.year}</div>
                    }
                    {(n.year === null && n.month !== null)
                      && <div className={classnames("year_dot")}></div>
                    }
                    {
                      n.month !== null
                      && <>
                        <div className={classnames("line",{isYearDuplicate : n.year === null})}></div>
                        <div className="month">{n.month}</div>
                      </>
                    }
                    {/*<div className="text">*/}
                    <div className={classnames("text",{no_year_box: (n.year !== null && n.month !== null)})}>
                      <p className={classnames("title",{isMonthDuplicate : n.month === null})}>
                        {n.title}
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
export default RoadMap;