import React, {useContext} from 'react';
import './modal.scss';
import {ModalDispatchContext} from "./ModalContext";

export default function Modal({id , openedModals, header, children, type, zIndexStyle, footer}) {
  const {close} = useContext(ModalDispatchContext);
  const closeModal = () => {
    close(id);
  };
  // console.log("openedModals : ",openedModals);
 // ******************************************5
  return (
    <article
      className={Array.isArray(openedModals) && openedModals.includes(id)
        ? 'openModal modal'
        : 'modal'
      }

      style={
        {zIndex: zIndexStyle === 2 && "10005"}
      }
    >

      {openedModals.includes(id) && type === "text" ? (
        <div className="modal_container">

          <div className="modal_header">
            <p>{header}</p>
            <img
              src={require('../../images/icons/popup_exit.svg').default}
              alt="닫기 버튼"
              onClick={closeModal}
            />
          </div>

          <div className="modal_main">
            {
              id === "termsModal" || id === "policyModal"
              ? <div dangerouslySetInnerHTML={ {__html: children} }></div>
                : <>{children}</>
            }
          </div>
          <div className="modal_footer">
            {footer}
          </div>
        </div>
      ) : null}

      {openedModals.includes(id) && type === "image" ? (
        <div className="image_modal">
          <img
            className="ext_btn"
            src={require('../../images/icons/popup_exit.svg').default}
            alt="닫기 버튼"
            onClick={closeModal}
          />
          {children}
        </div>
      )
      : null}
    </article>
  );
};
Modal.defaultProps = {
  type:"text",
  zIndexStyle:1
}
