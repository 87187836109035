import './common.scss';
import './default.scss';

import "./pages/layout/footer.scss"
import {Outlet} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import React from "react";
import Header from "pages/layout/Header";
import Footer from "pages/layout/Footer";


function App() {
  return (
    <>
      <ScrollToTop/>
      <div className="app">
          <Header/>
          <Outlet/>
          <Footer/>
      </div>
    </>
  );
}

export default App;