import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import "./trainee_main_nev.scss";
import classnames from "classnames";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import {CommonContext} from "../../contexts/CommonContext";

function TraineeMainNavTwo() {
  const { lastYear } = useContext(CommonContext);
  const location = useLocation();
  const [urlTitle, setUrlTitle ] = useState("");
  const [activeSlide, setActiveSlide] = useState(-1); // 현재 선택된 슬라이드의 인덱스

  const navigate = useNavigate();
  const traineeUrls = ["/trainee/trend","/trainee/all","/trainee/money","/trainee/training","/trainee/work","/trainee/welfare","/trainee/safety"]

  let initCount;
  const newUrlTitle = location.pathname.split("/")[2]
  initCount = traineeUrls.indexOf("/trainee/" +newUrlTitle);
  // console.log(`newUrlTitle : ${newUrlTitle} / initCount  : ${initCount}`)
  useEffect(() => {
    setUrlTitle(newUrlTitle);
  }, [ location])
  // ******************************************
  return (
    <div id="trainee_main_nav">
      <div id="top_box">
        <div className="trainee_title">
          <a href="/trainee">
            <img src={require("../../images/icons/medistaff_logo.svg").default}
                 alt="메디스태프 로고"/>
            <p>{lastYear} 전국 전공의 병원평가 결과</p>
          </a>
        </div>

        {/*병원 별 평가 상세결과 버튼*/}
        {/*<div className="top_hospital_btn" >*/}
        <Link to="/trainee/hospital">
          <div
            className={classnames("top_hospital_btn",{active : urlTitle === "hospital", not_active : urlTitle !== "hospital"})} >
        병원 별 평가 상세결과
          </div>
        </Link>
      </div>

      <div id="middle_box" className="not_mobile">
        <ul>
          <Link
            className={classnames("item", { active: (activeSlide === 0) || urlTitle === "trend"})}
            onClick={()=>setActiveSlide(0)}
            to="/trainee/trend">
            <p>2017 ~ {lastYear}</p>
            <span>5개년 트렌드</span>
            <div className={classnames({bar : urlTitle === "trend"})}></div>
          </Link>
          <Link
            className={classnames("item", { active: activeSlide === 1 || urlTitle === "all"})}
            onClick={()=>setActiveSlide(1)}
            to="/trainee/all">
            <p>{lastYear}</p>
            <span>종합순위</span>
            <div className={classnames({bar : urlTitle === "all"})}></div>
          </Link>
          <Link
            className={classnames("item", { active: activeSlide === 2 || urlTitle === "money"})}
            onClick={()=>setActiveSlide(2)}
            to="/trainee/money">
            <p>{lastYear}</p>
            <span>급여</span>
            <div className={classnames({bar : urlTitle === "money"})}></div>
          </Link>
          <Link
            className={classnames("item", { active: activeSlide === 3 || urlTitle === "training"})}
            onClick={()=>setActiveSlide(3)}
            to="/trainee/training">
            <p>{lastYear}</p>
            <span>교육환경</span>
            <div className={classnames({bar : urlTitle === "training"})}></div>
          </Link>
          <Link
            className={classnames("item", { active: activeSlide === 4 || urlTitle === "work"})}
            onClick={()=>setActiveSlide(4)}
            to="/trainee/work">
            <p>{lastYear}</p>
            <span>근로여건</span>
            <div className={classnames({bar : urlTitle === "work"})}></div>
          </Link>
          <Link
            className={classnames("item", { active: activeSlide === 5 || urlTitle === "welfare"})}
            onClick={()=>setActiveSlide(5)}
                to="/trainee/welfare">
            <p>{lastYear}</p>
            <span>복리후생</span>
            <div className={classnames({bar : urlTitle === "welfare"})}></div>
          </Link>
          <Link
            className={classnames("item", { active: activeSlide === 6  || urlTitle === "safety"})}
            onClick={()=>setActiveSlide(6)}
            to="/trainee/safety">
            <p>{lastYear}</p>
            <span>전공의 안전</span>
            <div className={classnames({bar : urlTitle === "safety"})}></div>
          </Link>
        </ul>
      </div>

      {/* region ****************** 모바일 전용 ****************** */}
      <div id="middle_box" className="mobile">
        <ul>
          <Swiper
            className={"sdfdsfsdfsd"}
            spaceBetween={50}
            slidesPerView={"auto"} // 슬라이드 너비에 맞게 보여줌
            centeredSlides={true}
            allowTouchMove
            initialSlide={initCount}
            slideToClickedSlide={true} // 클릭할 경우 슬라이드 이동
            observer={true}
            breakpoints={{
              0:{ // 0~ 280
                spaceBetween:20,
              },
              281:{
                spaceBetween:40,
              },
              481:{
                spaceBetween:60,
              },
            }}

            // +화면 중앙에 올 경우 경로 이동
            onActiveIndexChange={(swiper) => {
              setActiveSlide(swiper.activeIndex);
              navigate(traineeUrls[swiper.activeIndex]);
            }}
          >
            <SwiperSlide
              className={classnames("item", { active: activeSlide === 0  || urlTitle === "trend"})}
              onClick={()=>setActiveSlide(0)}
            >
              <Link to="/trainee/trend">
                <p>2017 ~ {lastYear}</p>
                <div className="text">5개년 트렌드</div>
                <div className="bar"></div>
              </Link>
            </SwiperSlide>

            <SwiperSlide
              className={classnames("item", { active: activeSlide === 1})}
              onClick={()=>setActiveSlide(1)}
            >
              <p>{lastYear}</p>
              <div className="text">종합순위</div>
              <div className="bar"></div>
            </SwiperSlide>

            <SwiperSlide
              className={classnames("item", { active: activeSlide === 2})}
              onClick={()=>setActiveSlide(2)}
            >
              <p>{lastYear}</p>
              <div className="text">급여</div>
              <div className="bar"></div>
            </SwiperSlide>

            <SwiperSlide
              className={classnames("item", { active: activeSlide === 3})}
              onClick={()=>setActiveSlide(3)}
            >
              <p>{lastYear}</p>
              <div className="text">교육환경</div>
              <div className="bar"></div>
            </SwiperSlide>

            <SwiperSlide
              className={classnames("item", { active: activeSlide === 4})}
              onClick={()=>setActiveSlide(4)}
            >
              <p>{lastYear}</p>
              <div className="text">근로여건</div>
              <div className="bar"></div>
            </SwiperSlide>

            <SwiperSlide
              className={classnames("item", { active: activeSlide === 5})}
              onClick={()=>setActiveSlide(5)}
            >
              <p>{lastYear}</p>
              <div className="text">복리후생</div>
              <div className="bar"></div>
            </SwiperSlide>

            <SwiperSlide
              className={classnames("item", { active: activeSlide === 6})}
              onClick={()=>setActiveSlide(6)}
            >
              <p>{lastYear}</p>
              <div className="text">전공의 안전</div>
              <div className="bar"></div>
            </SwiperSlide>
          </Swiper>
        </ul>
      </div>
    </div>
  )
}

export default TraineeMainNavTwo;