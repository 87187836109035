import {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getTerms} from "../../api/api";
import {ModalDispatchContext, ModalStateContext} from "./ModalContext";

export const ModalProvider = ({children}) => {
  const [termsOfService, setTermsOfService] = useState(); // 이용 약관
  const [privacyPolicy, setPrivacyPolicy] = useState(); // 개인정보 처리 방침

  /* 데이터 불러오기 */
  const {isLoading, error, data,} = useQuery(
    ['Term'],
    () => getTerms().then(res=>res.data)
    ,{
      staleTime:Infinity, keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      onSuccess:data=>{
        // console.log(data);
        setTermsOfService(data.content.termsOfService);
        setPrivacyPolicy(data.content.privacyPolicy);
      }
    }
  );

  const [openedModals, setOpenedModals] = useState([]);
  const open = (plusId) => {
    setOpenedModals(openedModals.concat(plusId));
    document.body.style.overflow = "hidden";
  };
  const close = (closedId) => {
    const newOpenedModals = openedModals.filter(modal => modal !== closedId);
    setOpenedModals(newOpenedModals);
    document.body.style.overflow = "auto";
  };
  const allClose = () => {
    setOpenedModals([]);
    document.body.style.overflow = "auto";
  };
  const openedModalsValue = {
    state: { openedModals}, actions: { setOpenedModals},
  };

  const dispatch = {open, close, allClose, termsOfService, privacyPolicy};

  return (
    <ModalDispatchContext.Provider value={dispatch}>
      <ModalStateContext.Provider value = {openedModalsValue}>
        {children}
      </ModalStateContext.Provider>
    </ModalDispatchContext.Provider>
  )
}