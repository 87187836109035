import React from 'react';
import "./loading.scss"

function Loading() {

 // ******************************************
  return (
    <div id="loading">
      {/*<div className="text">로딩중입니다. 잠시만 기다려주세요.</div>*/}
      <img src={process.env.PUBLIC_URL+"/images/icon/loading.gif"} alt="로딩중" />
    </div>
  )
}
export default Loading;
