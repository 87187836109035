import React, {useContext, useEffect, useState} from 'react';
import classnames from "classnames";
import {urlCopyBtnClick} from "../../../utils/common";
import BottomAccordion from "../BottomAccordion";
import {ModalDispatchContext,} from "../../../components/modal/ModalContext";
import {useQuery} from "@tanstack/react-query";
import {getTrainee} from "../../../api/api";
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {rankingQuestion, rankingSubject} from "./traineeRankingData";
import $ from "jquery";

function TraineeRanking() {
  const navigate = useNavigate();
  const location = useLocation();
  const [urlTitle, setUrlTitle ] = useState(0);

  useEffect(() => {
    setUrlTitle(location.pathname.split("/")[2])
  }, [ location, urlTitle])
// region ****************** 각 페이지마다 다른 부분 *****************************

  /* 데이터 불러오기 */
  const [traineeData, setTraineeData] = useState();
  const {isLoading, error, data,} = useQuery(
    ['trainee',urlTitle],
    () => getTrainee(urlTitle).then(res=>res.data)
    ,{
      keepPreviousData: true,
      enabled:!!urlTitle,
      onSuccess:data=>{
        window.aaa= data.content;
        setTraineeData(data.content);
        // console.log("data : "+JSON.stringify(data.content));
      }
    },[urlTitle]
  );

// endregion ****************** 각 페이지마다 다른 부분 *****************************

// region ****************** 공통 부분 *****************************
  const middleSubject = "종합 순위 "
  const [index, setIndex] = useState(0);

  const tabData = [
    {
      id:0,
      title:"대형병원 (500명-)",
      type:"500명 이상"
    },
    {
      id:1,
      title:"중대형병원 (200-500명)",
      type:"500명 이내 200명 이상",
    },
    {
      id:2,
      title:"중소형병원 (100-200명)",
      type:"200명 이내 100명 이상",
    },
    {
      id:3,
      title:"소형병원 (-100명)",
      type:"100명 미만",
    },
  ]

  // ******************************************
  const {open} = useContext(ModalDispatchContext)

  const openModal = (e) => {
    let userAgent = navigator.userAgent;
    if (userAgent.match(/iPhone|iPad|iPod/)) { // 아이폰의 경우
      window.open(process.env.REACT_APP_MEDISTAFF_IOS_APP_STORE);
    } else if (userAgent.match(/android/)) { // 안드로이드의 경우
      window.open(process.env.REACT_APP_MEDISTAFF_AOS_APP_STORE);
    } else { // 웹의 경우
      open(e.target.id);
    }
  }

  // 모바일의 경우
  const traineeTap = (e, item) => {
    let windowWidth = $( window ).width();
    if(windowWidth > 480){
      setIndex(item.id)
      return false;
    }

    const aaa = document.querySelectorAll(".tab_header_item");
    if (index === item.id) { // 펼치기
      document.querySelector(".accordion_icon").style.transform = `scaleY(-1)`;
      for (let i = 0; i < aaa.length; i++) {
        aaa[i].style.display = "block";
      }
    } else {
      document.querySelector(".accordion_icon").style.transform = `scaleY(1)`;
      setIndex(item.id)
      for (let i = 0; i < aaa.length; i++) {
        aaa[i].style.display = "none";
      }
      e.target.style.display = "block";
    }
  }
 // ******************************************
  if(!traineeData || isLoading || !urlTitle) return null;
  if(error) return <div>데이터를 불러올 수 없습니다.</div>;
  return (
    <article className="trainee_tap_content">
      <div id="total" className="tab_container">
        <div>
          <ul className="tab_header_box">
            <img className="accordion_icon" src={process.env.PUBLIC_URL+"/images/icon/accordion_icon.svg"} alt="accordion icon"/>
            {tabData.map(item => (
              <li
                key={item.id}
                className={classnames("tab_header_item",{active : index === item.id })}
                onClick={(e)=>traineeTap(e, item)}
              >
                <img src={process.env.PUBLIC_URL+"/images/icon/sub_tab_in_icon.svg"} alt="active icon"/>
                {item.title}
              </li>
            ))}
          </ul>

          <div className="tab_content_box">
            <div className="url_copy_btn_box">
              <div className="url_copy_btn"
                   onClick={urlCopyBtnClick}>
                링크 공유
              </div>
            </div>

            {tabData.filter(item => index === item.id).map(item => (
              <div
                key={item.id}
                className="tab_content_item_box">

                <div className="tab_content_item_title">
                  <div>
                    <span>{rankingSubject[urlTitle]}</span>
                    <b>{middleSubject}</b>
                  </div>
                  <span className="aaa">{item.title}</span>
                </div>

                <div className="tab_content_item_content">
                  {/*{item.content}*/}
                  <ul className="ranking_box">
                    {
                      traineeData.filter(item => item.type===tabData[index].type)
                      .map((item) => (
                        <li key={item.currentRank}>
                          <span className={classnames("ranking_number","ranking"+(index+1))}>
                            {item.currentRank === 1 && (<img src={process.env.PUBLIC_URL+"/images/trainee/icon/ranking1.svg"} alt="1위 icon"/>)}
                            {item.currentRank === 2 && (<img src={process.env.PUBLIC_URL+"/images/trainee/icon/ranking2.svg"} alt="2위 icon"/>)}
                            {item.currentRank === 3 && (<img src={process.env.PUBLIC_URL+"/images/trainee/icon/ranking3.svg"} alt="3위 icon"/>)}
                            {(item.currentRank === 4 || item.currentRank === 5) && (<span className="aaa">{item.currentRank}위</span>)}
                          </span>
                          <span className="ranking_dash">
                            {(item.oldRank === item.currentRank)
                              && <div className="rank_same"></div>}
                            {(item.oldRank < item.currentRank)
                              && <div className="rank_down"></div>}
                            {(item.oldRank > item.currentRank)
                              && <div className="rank_up"></div>}
                          </span>
                          <span className="ranking_name">{item.name}</span>
                          <img
                            className="home_image"
                            src={process.env.PUBLIC_URL+"/images/icon/list_home_icon.svg"}
                            alt="home icon"
                            onClick={()=> {
                              window.location.href = item.url;
                              }
                            }
                          />
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            ))}
            <button
              id="appStoreModal"
              onClick={openModal}
              type="button"
              className="view_more_btn"
            >
              병원 순위 더보기
            </button>

            <BottomAccordion
              id={rankingSubject[urlTitle]}
              contents={rankingQuestion[urlTitle]}
            />
          </div>
        </div>
      </div>
    </article>
  )
}
export default TraineeRanking;