import React from 'react';
import {useInfiniteQuery} from "@tanstack/react-query";
import {getNotice} from "../../api/api";
import NoticeItem from "./NoticeItem";
import "./notice.scss";

// TODO : 공지사항 날짜랑 제목만 불러오고 내용은 불러오지 않게 하기
function Notice() {
  // const { ref, inView} = useInView();
  const limitCount = 10; // 한번에 몇개씩 불러올 것인지

  /* 데이터 불러오기 */
  const { isLoading, isError, error, data, fetchNextPage, isFetchingNextPage, hasNextPage,fetchPreviousPage,hasPreviousPage } =
    useInfiniteQuery(
      ['notice'],
      ({ pageParam = 0 }) => getNotice(pageParam, limitCount),
      {
        staleTime: 5000, // 5초
        cacheTime: Infinity, // 제한 없음
        refetchOnWindowFocus: false,

        // 다음 페이지를 호출할 때 사용 될 pageParam
        getNextPageParam: (lastPage, allPages) => {
          window.lastPage  = lastPage;
          window.allpage  = allPages;
          const nextPageParam = Number(allPages.length);
          // console.log("nextPageParam : "+nextPageParam);
          return !lastPage.data.last && nextPageParam;
        },

        // 이전 페이지를 호출할 때 사용 될 pageParam
        // getPreviousPageParam: (firstPage, allPages) => {
        //   return allPages.length - 1;
        // },
        onSuccess:(data) => {
          window.data = data;
          // console.log("data : "+ JSON.stringify(data));
        },
        retry:false
      }
    )

  // useEffect(()=> {
  //   if(inView){
  //     fetchNextPage();
  //   }
  // }, [inView])

  if (isLoading  || !data) return <h2 style={{height:"500px"}}>Loading...</h2>
  if (isError) return <h2 style={{height:"500px"}}>{error.message}</h2>

 // ******************************************
  return (
    <div id="notice">
      <div id="contents">
        <div className="sub_header bg4">
          <div className="box">
            <div className="main_title sub">
              Notice
            </div>
          </div>
        </div>

        <div className="notice_container_box">
            {
              data.pages.map((item) => {
                return item.data.content.map((v)=>(
                  <NoticeItem
                    key={v.noticeIdx}
                    notice={v}/>
                ))
              })
            }
          <div className="btn_box" id="pressBtnBox">
            {hasNextPage &&<button className="btn_s" onClick={() => hasNextPage && fetchNextPage()}>View more</button>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Notice;

