import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const handleScrollTop = () => {
    window.scrollTo(0, 0);

    const windowWidth = $( window ).width();

    if(windowWidth <= 480){ // 모바일의 경우
      $('body').css('overflow','auto');
      // trainee 페이지의 경우
      if(pathname.indexOf("trainee") !== -1) {
        $('.top_menu').css('display', 'flex');
      }else{
        $('.slick-dots').css("display", "block" );
        $('.top_menu').fadeOut('fast');
        $('.top_icon').fadeOut('fast');
        $('.top_menu_bg').fadeOut('fast');
      }
    }
    else{ // 웹버전의 경우
      if(pathname.indexOf("trainee") === -1) { // trainee 페이지가 아닐때에만
        $('.top_menu').css('display', 'block');
      }else{
        $('.top_menu').css('display', 'flex');
      }
    }
  }

  // useEffect(() => {
    handleScrollTop();
  // }, );

  return null;
};