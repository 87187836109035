import React, { useContext, useEffect, useState, useRef } from 'react';
import './career.scss';
import './career/Slider.scss';
import Lottie from 'lottie-react';
import { useQuery } from '@tanstack/react-query';
import {
  ModalDispatchContext,
  ModalStateContext,
} from '../components/modal/ModalContext';
import useMoveScroll from '../test/useMoveScroll';
import CareerLottie from 'images/lottie/career_lottie.json';
import CareerBg from 'images/career_con_bg.png';
import AppImage_1_1 from 'images/career_m1-1.jpg';
import AppImage_1_2 from 'images/career_m1-2.jpg';
import AppImage_2_1 from 'images/career_m2-1.jpg';
import AppImage_2_2 from 'images/career_m2-2.jpg';
import AppImage_3_1 from 'images/career_m3-1.jpg';
import AppImage_3_2 from 'images/career_m3-2.jpg';
import AppImage_3_3 from 'images/career_m3-3.jpg';
import BenefitBg from 'images/career_benefit_bg.jpg';
import { SubTitle, TextContent, Title } from './career/Title';
import { ImageSlider } from './career/ImageSlider';
import { useCountUp } from '../utils/useCountUp';
import { numberWithComma } from '../utils/numberWithComma';
import { getCareerCurrent } from 'api/api';
import Modal from '../components/modal/Modal';
import ContactUs from '../components/modals/ContactUsPopup';

const imageList = {
  1: [
    {
      src: AppImage_1_1,
      alt: 'example_career',
    },
    {
      src: AppImage_1_2,
      alt: 'example_career',
    },
  ],
  2: [
    {
      src: AppImage_2_1,
      alt: 'example_career',
    },
    {
      src: AppImage_2_2,
      alt: 'example_career',
    },
  ],
  3: [
    {
      src: AppImage_3_1,
      alt: 'example_career',
    },
    {
      src: AppImage_3_2,
      alt: 'example_career',
    },
    {
      src: AppImage_3_3,
      alt: 'example_career',
    },
  ],
};

function Career() {
  const scrollItems = [
    useMoveScroll(),
    useMoveScroll(),
    useMoveScroll(),
    useMoveScroll(),
  ];
  // **********************
  const { open } = useContext(ModalDispatchContext);
  const openModal = (e) => open(e.currentTarget.id);
  const { state } = useContext(ModalStateContext);
  // ******************************************
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      // 컴포넌트가 뷰포트 안에 들어왔는지 확인
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false); // 뷰포트 밖으로 나가면 false로 설정
      }
    }
  };

  useEffect(() => {
    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);

    // 초기 스크롤 상태 체크
    handleScroll();

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const {
    // isLoading,
    // error,
    data: countData,
  } = useQuery(
    ['countData'],
    () =>
      getCareerCurrent().then((response) => {
        return JSON.parse(response.data.content);
      }),
    [],
  );

  const registeredUsers = numberWithComma(
    useCountUp(
      Number(countData ? countData.registeredUsers : 54000),
      700,
      isVisible,
    ),
  );
  const dailyUserVisits = numberWithComma(
    useCountUp(
      Number(countData ? countData.dailyUserVisits : 22000),
      700,
      isVisible,
    ),
  );
  const averageSessionDuration = numberWithComma(
    useCountUp(
      Number(countData ? countData.averageSessionDuration : 30),
      700,
      isVisible,
    ),
  );

  return (
    <article id="career">
      <div id="contents" className="contact_popup career_edu_popup">
        <div className="sub_header career">
          <div className="box">
            <div className="main_title sub">Career</div>
          </div>
        </div>

        <div className="sub_tab_box">
          <div className="box">
            <div className="sub_tab" onClick={scrollItems[0].onMoveToElement}>
              <img
                src={require('../images/sub_tab_icon_career1.svg').default}
                alt="icon"
              />
              <p>우수한 인재풀, 높은 가능성</p>
            </div>

            <div className="sub_tab" onClick={scrollItems[1].onMoveToElement}>
              <img
                src={require('../images/sub_tab_icon_career2.svg').default}
                alt="icon"
              />
              <p>손쉬운 초빙 등록/관리</p>
            </div>
            <div className="sub_tab" onClick={scrollItems[2].onMoveToElement}>
              <img
                src={require('../images/sub_tab_icon_career3.svg').default}
                alt="icon"
              />
              <p>안정한 소통 수단</p>
            </div>
            <div className="sub_tab" onClick={scrollItems[3].onMoveToElement}>
              <img
                src={require('../images/sub_tab_icon_career4.svg').default}
                alt="icon"
              />
              <p>원클릭 이력서</p>
            </div>
          </div>
        </div>
        <div
          className="h-210 md:h-320 flex justify-center items-center relative px-24 sm:px-0"
          style={{
            background: 'linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%)',
            overflow: 'hidden',
          }}
        >
          <div className="absolute h-full w-full overflow-hidden">
            <img
              src={CareerBg}
              className="h-full"
              alt="CareerBg"
              style={{ maxWidth: 'fit-content' }}
            />
          </div>
          <div className="w-320 h-130 md:w-600 md:h-240">
            <Lottie animationData={CareerLottie} loop={true} />
          </div>
        </div>
        <div
          className="px-24 py-50 sm:py-100 flex flex-col items-center overflow-hidden"
          ref={scrollItems[0].element}
        >
          <Title title="우수한 인재풀, 높은 가능성" />
          <SubTitle
            title="압도적으로 높은 2040 의사 가입률과 다양성을 가진 회원풀"
            className="hidden md:flex"
          />
          <SubTitle
            title="압도적으로 높은 2040 의사 가입률과 다양성을 가진 회원풀"
            className="md:hidden"
          />
          <TextContent>
            메디스태프는 대한민국 최초의 의사/의대생 전용 어플리케이션으로
            의사선생님의 라이프 사이클과 함께 합니다.
            <br />
            가장 구직활동을 활발히 하는 2040 젊은 의사선생님들이 앱 가입자중
            80%를 차지하고 있으며, 미래 잠재 가치를
            <br />
            지닌 의대생, 인턴, 레지던트부터 26개 전문과목의 전문의들까지
            함께하고 있습니다.
          </TextContent>
          <div className="mt-40 md:mt-80 flex flex-col md:flex-row gap-28 md:gap-40">
            <div
              className="w-260 md:w-240 h-120 rounded-24 flex flex-col justify-center items-center text-white"
              style={{
                background: 'linear-gradient(180deg, #4174EE 0%, #1F4BD5 100%)',
                boxShadow: '0px 2px 8px 0px #1F4BD5CC',
              }}
            >
              <span className="text-24 font-bold">
                {registeredUsers ? registeredUsers : 54000}+명
              </span>
              <span className="text-16 sm:text-18 leading-24 sm:leading-28">
                의사 / 의대생
              </span>
            </div>
            <div
              ref={ref}
              className="w-260 md:w-240 h-120 rounded-24 flex flex-col justify-center items-center text-white"
              style={{
                background: 'linear-gradient(180deg, #4174EE 0%, #1F4BD5 100%)',
                boxShadow: '0px 2px 8px 0px #1F4BD5CC',
              }}
            >
              <span className="text-24 font-bold">
                하루에 {dailyUserVisits}+명
              </span>
              <span className="text-16 sm:text-18 leading-24 sm:leading-28">
                꾸준히 방문하고
              </span>
            </div>
            <div
              className="w-260 md:w-240 h-120 rounded-24 flex flex-col justify-center items-center text-white"
              style={{
                background: 'linear-gradient(180deg, #4174EE 0%, #1F4BD5 100%)',
                boxShadow: '0px 2px 8px 0px #1F4BD5CC',
              }}
            >
              <span className="text-24 font-bold">
                매일 {averageSessionDuration}+분씩
              </span>
              <span className="text-16 sm:text-18 leading-24 sm:leading-28">
                머물며 소통하는 플랫폼
              </span>
            </div>
          </div>
        </div>
        <div
          className="px-24 pt-50 sm:pt-100 flex flex-col items-center overflow-hidden"
          ref={scrollItems[1].element}
          style={{ background: '#F6F7F9' }}
        >
          <Title title="공고 등록부터 지원자 관리까지 쉽고 빠르게" />
          <SubTitle
            title="앱에서 바로 공고를 등록하고, 지원자에게 바로 합격 소식을 전하세요"
            className="hidden md:flex"
          />
          <SubTitle
            title="앱에서 바로 공고를 등록하고, 지원자에게 바로 합격 소식을 전하세요"
            className="md:hidden"
          />
          <TextContent>
            초빙 담당자이신가요? 메디스태프에서 쉽고 빠르게 초빙 공고를 등록해
            보세요.
            <br /> 내가 올린 공고에 지원자가 생기면, 앱에서 즉시 이력서 확인 후
            지원자에게 합격여부를 안내할 수 있습니다.
          </TextContent>
          <div className="mt-40 md:mt-80 w-full flex justify-center">
            <div className="hidden md:flex gap-80 overflow-hidden">
              <div className="relative w-296 h-462">
                <img
                  src={require('../images/iphone_frame_new.svg').default}
                  alt="example_career"
                  style={{
                    position: 'absolute',
                    top: 0,
                    zIndex: 2,
                  }}
                />
                <div className="relative w-258">
                  <img
                    src={AppImage_1_1}
                    alt="example_career"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      zIndex: 1,
                      borderRadius: '26px',
                      left: '20px',
                    }}
                  />
                </div>
              </div>
              <div className="relative w-296 h-462">
                <img
                  src={require('../images/iphone_frame_new.svg').default}
                  alt="example_career"
                  style={{
                    position: 'absolute',
                    top: 0,
                    zIndex: 2,
                  }}
                />
                <div className="relative w-258">
                  <img
                    src={AppImage_1_2}
                    alt="example_career"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      zIndex: 1,
                      borderRadius: '26px',
                      left: '20px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="md:hidden min-h-380">
              <ImageSlider imageList={imageList[1]} />
            </div>
          </div>
        </div>
        <div
          className="px-24 pt-50 sm:pt-100 flex flex-col items-center overflow-hidden"
          ref={scrollItems[2].element}
        >
          <Title title="보안 채팅으로 안전하게 소통" />
          <SubTitle title="근무여건을 좀 더 자세히 조율하고 싶을 때" />
          <TextContent>
            담당자도, 지원자도, 서로 조율이 필요한 근무 여건과 급여 등 세부적인
            사항을 메디스태프에서 제공하는 보안 채팅 서비스를 통해 안전하게
            이야기 나눠 보세요.
          </TextContent>
          <div className="mt-40 md:mt-80 w-full flex justify-center">
            <div className="hidden md:flex gap-80 overflow-hidden">
              <div className="relative w-296 h-462">
                <img
                  src={require('../images/iphone_frame_new.svg').default}
                  alt="example_career"
                  style={{
                    position: 'absolute',
                    top: 0,
                    zIndex: 2,
                  }}
                />
                <div className="relative w-258">
                  <img
                    src={AppImage_2_1}
                    alt="example_career"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      zIndex: 1,
                      borderRadius: '42px',
                      left: '20px',
                    }}
                  />
                </div>
              </div>
              <div className="relative w-296 h-462">
                <img
                  src={require('../images/iphone_frame_new.svg').default}
                  alt="example_career"
                  style={{
                    position: 'absolute',
                    top: 0,
                    zIndex: 2,
                  }}
                />
                <div className="relative w-258">
                  <img
                    src={AppImage_2_2}
                    alt="example_career"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      zIndex: 1,
                      borderRadius: '26px',
                      left: '20px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="md:hidden min-h-380">
              <ImageSlider imageList={imageList[2]} />
            </div>
          </div>
        </div>
        <div
          className="px-24 pt-50 sm:pt-100 flex flex-col items-center overflow-hidden"
          ref={scrollItems[3].element}
          style={{ background: '#F6F7F9' }}
        >
          <Title title="내 프로필로 완성된 원클릭 이력서" />
          <SubTitle title="별도로 쓸 필요 없이 자동 완성" />
          <TextContent>
            내 프로필만 작성해 두면, 언제든 쓸 수 있는 이력서가 준비됩니다.
            <br />
            공개/비공개 항목을 적용하여 이력서를 원클릭으로 만들고 원하는
            포지션에 빠르게 지원해 보세요.
          </TextContent>

          <div className="mt-40 md:mt-80 w-full flex justify-center">
            <div className="hidden md:flex gap-32 overflow-hidden">
              <div className="relative w-296 h-462">
                <img
                  src={require('../images/iphone_frame_new.svg').default}
                  alt="example_career"
                  style={{
                    position: 'absolute',
                    top: 0,
                    zIndex: 2,
                  }}
                />
                <div className="relative w-258">
                  <img
                    src={AppImage_3_1}
                    alt="example_career"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      zIndex: 1,
                      borderRadius: '26px',
                      left: '20px',
                    }}
                  />
                </div>
              </div>
              <div className="relative w-296 h-462">
                <img
                  src={require('../images/iphone_frame_new.svg').default}
                  alt="example_career"
                  style={{
                    position: 'absolute',
                    top: 0,
                    zIndex: 2,
                  }}
                />
                <div className="relative w-258">
                  <img
                    src={AppImage_3_2}
                    alt="example_career"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      zIndex: 1,
                      borderRadius: '26px',
                      left: '20px',
                    }}
                  />
                </div>
              </div>
              <div className="relative w-296 h-462">
                <img
                  src={require('../images/iphone_frame_new.svg').default}
                  alt="example_career"
                  style={{
                    position: 'absolute',
                    top: 0,
                    zIndex: 2,
                  }}
                />
                <div className="relative w-258">
                  <img
                    src={AppImage_3_3}
                    alt="example_career"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      zIndex: 1,
                      borderRadius: '26px',
                      left: '20px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="md:hidden min-h-380">
              <ImageSlider imageList={imageList[3]} />
            </div>
          </div>
        </div>
        <div className="h-240 md:h-400 relative overflow-hidden flex justify-center">
          <img
            src={BenefitBg}
            alt="BenefitBg"
            className="w-full h-full sm:h-auto absolute md:-top-250 left-0 right-0 md:object-contain"
          />
          <div
            className="absolute w-full md:w-980 h-full  flex flex-col items-center md:items-start justify-center px-16 sm:px-0"
            style={{ zIndex: 2 }}
          >
            <div
              className="text-white text-16 sm:text-28 font-bold leading-24 sm:leading-40 mb-12 text-center sm:text-left"
              style={{ textShadow: '0 0 5px #00000033' }}
            >
              가치를 최대로 끌어올릴 수 있는,
              <br />
              메디스태프와 초빙을 함께 하세요
            </div>
            <div
              className="text-white text-14 sm:text-20 font-medium leading-20 sm:leading-28 mb-24 text-center sm:text-left"
              style={{ textShadow: '0 0 5px #00000033' }}
            >
              의사 회원이라면, 앱에서 초빙 등록이 가능합니다.
              <br />
              초빙 상품의 자세한 사항은 문의해 주세요.
            </div>
            <div>
              <button
                className="text-18 font-bold leading-26 text-white px-40 py-15 rounded-10"
                style={{
                  background:
                    'linear-gradient(122.91deg, #4174EE 11.37%, #1F4BD5 84.17%)',
                  boxShadow: '0px 0px 12px 0px #1F4BD54D',
                }}
                onClick={openModal}
                id="contactUsModal_CAREER"
              >
                초빙 문의하기
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="contactUsModal_CAREER"
        openedModals={state.openedModals}
        header="Contact us"
      >
        <ContactUs inquiryType="CAREER" />
      </Modal>
    </article>
  );
}

export default Career;
