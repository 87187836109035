import React from 'react';
import classnames from "classnames";

function MainCard({children, subject, content, count}) {
  return (
    <>
    <div
      className={classnames('line_box', {four : count === 4})}
    >
      <div className="box_px">
        <div className={classnames('icon_oval', {four : count === 4})}>
          {children}
        </div>
        <div className="in_title">{subject}</div>
        <p>{content}</p>
      </div>
    </div>
    </>
  );
};

MainCard.defaultProps = {
  count:3
}
export default MainCard;