import React, {useContext, useEffect} from 'react';
import Modal from "../modal/Modal";
import ContactUs from "./ContactUsPopup";
import {ModalDispatchContext, ModalStateContext} from "../modal/ModalContext";
import {useQuery} from "@tanstack/react-query";
import {getActivePopupId, getActivePopupIdList, getTerms} from "../../api/api";
import {useCookies} from "react-cookie";
import {addDaysToDate} from "../../utils/common";


const MainLoadingPopups = () => {
  const {state} = useContext(ModalStateContext)
  const {open, close} = useContext(ModalDispatchContext)
  const [appCookies, setAppCookies] = useCookies();

  /* 모달을 보여줄것인지 말것인지 */
  const {isLoading, error, data,} = useQuery(
      ['popup'],
      () => getActivePopupId().then(res=>res.data)
      ,{
        onSuccess:_data=>{
          // TypeError: Cannot read properties of null (reading 'img_src') 이런 에러가 발생함....
          if(!!_data.content && !!_data.content.img_src){
            // console.log(`${_data.content.popup_id}_main_popup 팝업 보여줌`);
            open(`${_data.content.popup_id}_main_popup`);
          }
        },
        enabled:!appCookies["MODAL_EXPIRES"]
      }
  );
  window.data=data;
  // console.log("popup data : ",data);

  // 유효기간이 1일인 쿠키를 생성하고 모달을 닫는 함수
  const closeModalUntilExpires = (modalId) => {
    if (!appCookies) return;

    const expires = addDaysToDate(1);
    setAppCookies("MODAL_EXPIRES", true, { path: "/", expires });

    close(modalId);
  };

  // -----------------------------------------------------------
  return (
      <article className="C_MainLoadingPopups">

        {/* 테스트용 팝업 이미지*/}
        <Modal
            id="test_popup_main_popup"
            openedModals={state.openedModals}
            header=""
            footer={<MainPopupModalButton
                id="test_popup_main_popup"
                closeModalUntilExpires={closeModalUntilExpires}
            />}
        >
          <TestCookieModal/>
        </Modal>

        {/* 20240322 메디스태프 이용 안내*/}

        {data && data.content && data.content.img_src && (
            <Modal
            id={`${data?.content?.popup_id}_main_popup`}
            openedModals={state.openedModals}
            header=""
            footer={<MainPopupModalButton
                id={`${data.content?.popup_id}_main_popup`}
                closeModalUntilExpires={closeModalUntilExpires}
            />}
        >
          <ImagePopupContent
              data={data}
          />
        </Modal>
        )}

      </article>
  );
};

function TestCookieModal() {
  return (
      <article id="test_popup_main_popup" className="popup_content main_popup">
        <img className="error_img"
             src={process.env.PUBLIC_URL + "/images/error401.png"}
             alt="accordion icon"/>
      </article>
  )
}
function ImagePopupContent({data}) {
  const {close} = useContext(ModalDispatchContext)
  return (
      <article
          id={data.content.popup_id} className="popup_content main_popup">
        <img className="error_img"
             src={data.content.img_src}
             alt={data.content.popup_id}/>
      </article>
  )
}

function MainPopupModalButton({id, closeModalUntilExpires}) {
  const {close} = useContext(ModalDispatchContext)
  return (
      <div className="C_MainPopupModalButton">
      <button type="button" onClick={()=>closeModalUntilExpires(id)}>오늘 하루 보지않기</button>
      <button type="button" onClick={()=> close(id)}>닫기</button>
    </div>
)
}

export default MainLoadingPopups;