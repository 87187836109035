import React, {useContext} from 'react';
import {moveMobileAppStore, urlCopyBtnClick} from "../../../utils/common";
import {
  ModalDispatchContext,
  ModalStateContext
} from "../../../components/modal/ModalContext";
import {rankingSubject} from "./traineeRankingData";
import {
  commonOption,
  hospitalAllChartData,
  radarChartOptions
} from "./ChartData";
import {Radar} from "react-chartjs-2";
import Modal from "../../../components/modal/Modal";
import {ImageModalDesign} from "../BottomAccordion";

function ResultsByHospital() {
  // ******************************************
  const {open} = useContext(ModalDispatchContext);
  const {state } = useContext(ModalStateContext);

  const moveMobileAppStore = (e) => {
    let userAgent = navigator.userAgent;
    if (userAgent.match(/iPhone|iPad|iPod/)) { // 아이폰의 경우
      window.open(process.env.REACT_APP_MEDISTAFF_IOS_APP_STORE);
    } else if (userAgent.match(/android/)) { // 안드로이드의 경우
      window.open(process.env.REACT_APP_MEDISTAFF_AOS_APP_STORE);
    } else { // 웹의 경우
      open(e.target.id);
    }
  }
  // ******************************************
  return (
    <>
    <article id="result">
      <div className="url_copy_btn_box">
        <div className="url_copy_btn"
             onClick={urlCopyBtnClick}>
          링크 공유
        </div>
      </div>

      <div className="content_box">
        <div className="content_header">
          <div>
            <span>병원 별 평가 상세결과</span>
            <b>삼성서울병원</b>
          </div>
          <span className="aaa">종합</span>
        </div>
        <div className="content">
          <div className="chart_box">
            <Radar data={hospitalAllChartData} options={radarChartOptions}/>
          </div>
          <button
            className="bbb"
            id="appStoreModal"
            onClick={moveMobileAppStore}
          >
            삼성서울병원 외 다른 병원의 평가결과가 궁금하다면?
          </button>

        </div>
      </div>

      <button
        id="appStoreModal"
        onClick={moveMobileAppStore}
        type="button"
        className="view_more_btn"
      >
        병원 순위 더보기
      </button>

      <div className="data_source">
        데이터출처 : 대한전공의협의회
        <p className="copyright">본 자료의 저작권은 대한전공의협의회에 있으며, 메디스태프 이외 곳에 게재 및 배포를 금지합니다.</p>
      </div>
    </article>
    <Modal
      id="appStoreModal"
      openedModals={state.openedModals}
      header="제목"
      type="image"
    >
      <ImageModalDesign/>
    </Modal>
      </>
  )
}
export default ResultsByHospital;