import axios from 'axios';
import Swal from 'sweetalert2';
const baseUrl = process.env.REACT_APP_BACKEND_URL + '/api';

// json을 formdata로 변경
function returnFormData(params) {
  const formData = new FormData();
  Object.entries(params).forEach((item) => formData.append(item[0], item[1]));
  return formData;
}

export async function getTerms() {
  return axios.get(baseUrl + '/terms');
}

// useInfiniteQuery 사용시
export const getPress = (pageParam, limitCount) => {
  return axios.get(
    baseUrl + `/press?currentPage=${pageParam}&limitCount=${limitCount}`,
  );
};

// *************** 공지사항 ***************************
export async function getNotice(pageParam, limitCount) {
  // alert("currentPage : "+pageParam);
  return axios.get(
    `${baseUrl}/notice?currentPage=${pageParam}&limitCount=${limitCount}`,
  );
}

export async function getNoticeById(noticeId) {
  return axios.get(baseUrl + '/notice/' + noticeId);
}

//  현재 활성화된 팝업 가져오기
export async function getActivePopupId() {
  return axios.get(baseUrl + '/popup');
}

/*
교육수련부/일반사용자 초빙 등록하기
name: '',
hospital: '',
phone_number: '',
email: '',
educationDepartment:isEducationDepartment,
needLeaflet:false,
*/
export async function insertInvitation(params) {
  return axios.post(baseUrl + '/email/invitation', returnFormData(params));
}

/*
일반 홈페이지 관련 문의
name: '',
email: '',
content: '',
*/
export async function insertQuestion(params) {
  return axios.post(baseUrl + '/email/question', returnFormData(params));
}

// region *************** 병원평가 ***************************
// const params={
//    wantColumn:'all',
//    wantYear:2021
// }
export async function getTrainee(type) {
  // alert(type);
  return axios.get(baseUrl + `/trainee/total?wantColumn=${type}&wantYear=2022`);
}
// endregion *************** 병원평가 ***************************

export async function getCareerCurrent() {
  return axios.get(
    'https://api.medistaff.co.kr/api/public/medi-home/career-current',
  );
}
