import React from 'react';
import './main.scss'
import MainTopBanner from "./MainTopBanner";
import MainMiddleIntroduce from "./MainMiddleIntroduce";
import MainBottom from "./MainBottom";

function Main() {

 // ******************************************
  return (
    <section id="wrap">

      <div id="contents">
        <MainTopBanner/>
        <MainMiddleIntroduce/>
        <MainBottom/>
      </div>
    </section>
  );
};
export default Main;