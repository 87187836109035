import React from 'react';

function MainTopBanner() {
  return (
    <div className="main_header">
      <div className="box main">
        <div className="main_title">
          <p className="sub">의사들의 커뮤니케이션 플랫폼</p>
          <p>
            Better Security,<br/>
            Better Network,<br/>
            Better Healthcare
          </p>
        </div>
      </div>
    </div>
  );
};
export default MainTopBanner;